// https://stackoverflow.com/questions/48150838/how-to-access-to-variable-in-outside-the-map-function-in-laravel-php
// https://flexiple.com/javascript/loop-through-object-javascript/
// https://stackoverflow.com/questions/44969877/if-condition-inside-of-map-react
// https://stackoverflow.com/questions/62336340/cannot-update-a-component-while-rendering-a-different-component-warning
import React, {useState, useEffect, Children, useRef } from "react"

import Sound from '../components/Sound.js'
import Soundvolume from '../components/Soundvolume.js'
import content from '../data/soundsDB.js'
import { motion, useAnimation } from "framer-motion"
import './Sounds.css'

// Sounds.js receives the following props: 
// searchQ              => το περνάμε στο Sound.js
// SearchQterm          => το περνάμε στο Sound.js
// addSoundToDB         => το περνάμε στο Sound.js
// database*            => χρησιμοποιείται και δεν περνάει στο Sound.js
// modifyisactive       => το περνάμε στο Sound.js
// removeSoundFromDb    => το περνάμε στο Sound.js
// modifyVolume(-)      => δεν κάνει τίποτα και δεν το περνάμε πουθενά
// activename           => το περνάμε στο Sound.js
// triggerExpandShow(-) => δεν κάνει τίποτα και δεν το περνάμε πουθενά
export default function Sounds(props) {
    // Start_Block #1 ---
    // This code block is used to count which sounds are direct, relative or hidden sounds according to the search result
    let [countDirectSounds, setCountDirectSounds] = useState(0)
    const [tempCountDirectSounds, setTempCountDirectSounds] = useState(0)

    let [countRelativeSounds, setCountRelativeSounds] = useState(0)
    const [tempCountRelativeSounds, setTempCountRelativeSounds] = useState(0)

    let [countHiddenSounds, setCountHiddenSounds] = useState(0)
    const [tempCountHiddenSounds, setTempCountHiddenSounds] = useState(0)

    const initCountRirect = () => {
        setCountDirectSounds(0)
        setTempCountDirectSounds(0)
        setCountDirectSounds(0)
    }

    const initCountRelative = () => {
        setCountRelativeSounds(0)
        setTempCountRelativeSounds(0)
        setCountRelativeSounds(0)
    }

    const initCountHidden = () => {
        setCountHiddenSounds(0)
        setTempCountHiddenSounds(0)
        setCountHiddenSounds(0)
    }

        const countDirect = (e) => {
            if (e !== 0){
                countDirectSounds++
                setCountDirectSounds(countDirectSounds)
                setTempCountDirectSounds(countDirectSounds)
                setCountDirectSounds(0)
            } else {
                initCountRirect()
            }
        }

        const countRelative = (e) => {
            if (e !== 0){
                countRelativeSounds++
                setCountRelativeSounds(countRelativeSounds)
                setTempCountRelativeSounds(countRelativeSounds)
                setCountRelativeSounds(0)
            } else {
                initCountRelative()
            }        
        }

        const countHidden = (e) => {
            if (e !== 0){
                countHiddenSounds++
                setCountHiddenSounds(countHiddenSounds)
                setTempCountHiddenSounds(countHiddenSounds)
                setCountHiddenSounds(0)
            } else {
                initCountHidden()
            }
        }

        useEffect(() => {
            if((countDirectSounds + countRelativeSounds) === content.length-1) {
                setCountHiddenSounds(0)
                setTempCountHiddenSounds(0)
                setCountHiddenSounds(0)
            } else if (countHiddenSounds === content.length-1) {
                setCountDirectSounds(0)
                setTempCountDirectSounds(0)
                setCountDirectSounds(0)
    
                setCountRelativeSounds(0)
                setTempCountRelativeSounds(0)
                setCountRelativeSounds(0)
            } else if ((countDirectSounds + countHiddenSounds) === content.length-1) {
                setCountRelativeSounds(0)
                setTempCountRelativeSounds(0)
                setCountRelativeSounds(0)
            } else if ((countRelativeSounds + countHiddenSounds) === content.length-1) {
                setCountDirectSounds(0)
                setTempCountDirectSounds(0)
                setCountDirectSounds(0)
            }
        })
    
        useEffect(() => {
            if(tempCountRelativeSounds === content.length) {
                // alert("heeyyyyyyyyyyyyy")
                setCountDirectSounds(0)
                setTempCountDirectSounds(0)
            }
        }, [tempCountRelativeSounds]);
       
    // End_Block #1 ---

        
    // Start_Block #2 ---
    // This code sets isInitialMount to false only once, right after the initial render of the component. 
    // It's used to execute some code that should not run during subsequent renders of the component but should only run when the component is initially mounted.
    // More precisely it is used inside Sound.js (child component - individual sounds):
    //   A) firstMount is a boolean prop that indicates if this is the initial render of the component
    //   B) It is used to skip certain animations/logic that should only happen on subsequent renders, not the first mount
    //   C) This helps optimize performance and prevent unnecessary operations during the initial component mount
    //   D) It's a common pattern when you need to handle the first render differently from subsequent renders in React components
    //     i) It is passed to the fadeIn/fadeOut CSS classes to prevent the fade animation from happening on the initial mount.
    //     ii) It is passed to the SearchNotification component to prevent it from showing the "not found" message on the first render.
    //     iii) It is used in the useEffect hook that runs when the search term changes, to prevent setting state and triggering a re-render on the first mount
    const isInitialMount = useRef(true)

    useEffect(() => {
        if (isInitialMount.current) {
          isInitialMount.current = false;
          return;
        }
    }, [isInitialMount])
    // End_Block #2 ---

   

    // Start_Block #4 ---
        // The activeSoundsDB variable is used to track which sounds are currently active (playing) in the audio player.
        // SO, activeSoundsDB acts as a centralized store to track the state of which sounds are playing. 
        // It allows related components to share this active sounds state in a simplified way via props, rather than each component managing it independently.
        //   A) Check if a sound is already active when mapping over the list of all sounds. This is done by comparing the id to the dbid property of each active sound.
        //   B) Pass to each Sound component as the isactive prop, to indicate if that particular sound is active.
        //   C) Remove a sound when it is deactivated, by calling removeSoundFromDB and passing the id.
        //   D) Add a sound when it is activated, by calling addSoundToDB and passing all the sound properties.
        const activeSoundsDB = props.database
            // const childrenCount = React.Children.count(props.children);
    // End_Block #4 ---

    const [justStarted, setjustStarted] = useState(false)
    const [triggerOutAnimation, setTriggerOutAnimation] = useState(false);
    const [timerId, setTimerId] = useState(null);

    const justClicked = (soundStarting) => {
        // alert(soundStarting)
        setjustStarted(soundStarting)

        if (timerId) {
            clearTimeout(timerId);
        }

        setTriggerOutAnimation(true);

        setTimeout(() => {
            setTriggerOutAnimation(false);
        }, 500);

        const newTimerId = setTimeout(() => {
            setjustStarted(false);
        }, 5000);
    
        // Store the timer ID in state
        setTimerId(newTimerId);
    }

    useEffect(() => {
        return () => {
          if (timerId) {
            clearTimeout(timerId);
          }
        };
    }, [timerId]);

 // Start_Block #3 ---
        // it creates a responsive 6-column grid with centered content, 40px top padding, relative positioning for overlays, and a fixed width of 1200px.
        // This allows the Sound components to be rendered in a consistent grid format aligned both vertically and horizontally. 
        // The relative positioning also allows for absolute positioned overlays like the search not found message.
        const customsGridStyle = {
            gridContainer: (indexToHide) => ({
                display: 'grid',
                gridTemplateColumns: 'repeat(6, 1fr)',
                gridAutoRows: '100px',
                alignItems: 'center',
                justifyItems: 'center',
                top: '25px',
                position: 'relative',
                width: '1200px',
                // [`&:nth-child(${indexToHide})`]: {
                //   display: 'none',
                // },
              }),
              
          };


    // End_Block #3 ---

    const [hideGridChild, setHideGridChild] = useState(false)
    const [nthChildToHide, setNthChildToHide] = useState(null);

    // if (nthChildToHide) {
    //     // alert(nthChildToHide)
    //     customsGridStyle.gridContainer[`& :nth-child(${nthChildToHide})`] = { display: 'none' };
    //   }

    // useEffect(() => {
    //     if (props.activeName === 'New') {
    //       // Change the nth child to hide dynamically based on your logic
    //       setNthChildToHide(1); // For example, hide the 7th child
    //     //   customsGridStyle.hideNthItem(2)
    //     //   console.log(customsGridStyle.hideNthItem(2))
    //     } else {
    //       // Reset to the default value (5th child)
    //       setNthChildToHide(null);

    //     }
    // }, [props.activeName]);

    const displayIndividualSounds = content.map((newSound) => {
        let act = false

        // Εδώ ελέγχουμε αν ο ήχος είναι ήδη ενεργός, αν ναι, τότε η μεταβλητή act γίνεται true
        activeSoundsDB.forEach((activeSound) => {
          if (newSound.id == activeSound.dbid) {
            // act = true
            act = activeSound.isactive
            // props.modifyActiveState2()
          }       
        })

        return (
          <Sound
            key={newSound.id}
            id={newSound.id}
            sound={newSound.name}
            state={newSound.state}
            category={newSound.category}
            dconnection={newSound.dconnection}
            indconnection={newSound.indconnection}
            description={newSound.description}
            volume={newSound.volume}
           
            isactive={act}
            addSoundToDB={props.addSoundToDB}
            removeSoundFromDB={props.removeSoundFromDB}
            modifyisactive={props.modifyisactive}
            activeName={props.activeName}
            searchQ={props.searchQ}
            searchQterm={props.searchQterm}
            lengthOf={content.length}

            firstMount={isInitialMount.current}

            justClicked={justClicked}

            tempCountDirectSounds={tempCountDirectSounds}
            tempCountRelativeSounds={tempCountRelativeSounds}
            tempCountHiddenSounds={tempCountHiddenSounds}
            countRelative={countRelative}
            countDirect={countDirect}
            countHidden={countHidden}
            triggerExpandShadow={props.triggerExpandShadow}>
          </Sound>
        )
    }) 

    const [animation, setAnimation] = useState(false)

    useEffect(() => {
        setAnimation(true);

        // const timeoutId = setTimeout(() => {
        //     setAnimation(false);
        // }, 310);
        // return () => clearTimeout(timeoutId);
    }, [props.searchQterm])

    // https://www.react-spring.dev/docs/components/parallax
    return (
        // na mpei se style
        // <div className={`Grid ${nthChildToHide ? customsGridStyle.hideNthItem(nthChildToHide) : null}`} style={customsGridStyle.gridContainer}>
        <div className={`Grid `} style={customsGridStyle.gridContainer(1)}>

    <motion.div 
        initial={{ y: -6, opacity: 0 }}
        // animate={justStarted ? { y: 3, opacity: 1 } : { y: -6, opacity: 0 }}
        animate={
            justStarted
              ? triggerOutAnimation
                ? { y: -6, opacity: 0 }
                : { y: 0, opacity: 1 }
              : { y: -14, opacity: 0 }
          }
        transition={{ type: "tween", duration: 0.05, delay: 0.2 }}
        className="volume"
    >
        <Soundvolume/>
    </motion.div>

            {/*{childrenCount}*/}
            { displayIndividualSounds ? (displayIndividualSounds) : ("Failure") }
            <div className={
                `serachNotFound
                
                ${animation ? "fOut" : "fIn"}

                ${tempCountHiddenSounds === (content.length-1)  ? ("cancel") : ("")}`}>
                Oops... sound not found!  
            </div>
        </div>
    )
}