import React, { useState } from 'react';
import { Grow, Paper, Typography } from '@mui/material';

function MaterialDesignComponent(props) {
  // state to control the grow
  const [open, setOpen] = useState(false);

  // function to toggle the grow
  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  return (
    <div>
      <Paper
        sx={{
          width: open ? 300 : 100,
          height: open ? 200 : 100,
          cursor: 'pointer',
          backgroundColor: props.color || 'grey.300',
        }}
        onClick={handleClick}
      >
        <Grow in={open}>
          <Typography variant="h4" align="center">
            {props.title || 'My Component'}
          </Typography>
        </Grow>
        <Grow in={open}>
          <div>{props.children}</div>
        </Grow>
      </Paper>
    </div>
  );
}

export default MaterialDesignComponent;

