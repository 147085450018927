import React, {useEffect, useState} from 'react'
import Draggable, {DraggableCore} from 'react-draggable';

import './Volume.css'

export default function Volume(props) {
    const volumeContainer = {
        width: props.fw ? '100%' : props.width + 'px',
        height: props.height + 'px',
        border: '0px solid var(--main-color)',
        borderRadius: '2px',
        cursor: 'pointer',
        background:'#502166'
    }    

    const volumeProgress = {
        width: props.volume*100 + '%',
        height: '100%',
        background:'rgba(217, 217, 217, 0.5)'
    }

    const volumeMark = {
        transform: 'translateX(0px)',
        transform: 'translateY(-4.5px)',
        width: props.mark + 'px',
        height: props.mark + 'px'
    }

    const eventLogger = (e, data) => {
        // console.log('Event: ', e)
        var realX = (data.x + 5)/props.width
        props.modifyMasterVolume(realX)
    }

    useEffect(() => {
        
    }, [])

    return (
        <div style={volumeContainer} className='volume-container'>
            <div style={volumeProgress} className='volume-progress'>
            <Draggable
                axis="x"
                bounds={{top: 0, left: -5, right: props.width-(props.mark)/2, bottom: 0}}
                defaultPosition={{x: props.volume*props.width-(props.mark)/2, y: -(props.mark - props.height)/2}}
                scale={1}
                onDrag={eventLogger}
                // onStop={this.handleStop}
                // position={null}
                grid={[2.5, 2.5]}
                // onStart={this.handleStart}
                // handle=".handle"
                >
                <div style={volumeMark} className='mark'></div>
            </Draggable>
            </div>
        </div>
        
    )
}