import React, { memo, useState, useEffect, useRef, useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";
import { motion, useAnimation } from "framer-motion";
import "./Landscape.css";

const Landscape = memo((props) => {
  const [pixels, setPixels] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const gradientAnimationControls = useAnimation();

  useEffect(() => {
    // Simulating a loading state for 3 seconds
    const timeoutId = setTimeout(() => {
      setIsLoading(!isLoading);
    }, 0);

    return () => clearTimeout(timeoutId);
  }, [props.colorful]);

  useEffect(() => {
    if (!isLoading) {
      gradientAnimationControls.start({
        opacity: 1,
        transition: {
          duration: 0.15,
        },
      });
    }
    
    // Trigger the animation when props.colorful changes
    if (props.colorful) {
      gradientAnimationControls.set({ opacity: 0 }); // Reset opacity to 0
    }
    
  }, [isLoading, gradientAnimationControls, props.colorful]);

  const gradientStyles = {
    background: props.colorful
      ? "linear-gradient(135deg, rgb(7, 167, 182), rgb(233, 177, 184))"
      : "radial-gradient(131.26% 141.9% at 78.68% 27.52%, #5C228B 0%, rgba(112, 34, 139, 0.496875) 16.5%, rgba(131, 34, 139, 0) 100%)",
  };


// const gradientElement = document.querySelector('.gradient-animation');
// let rotationDegrees = 135; // Initial degrees value

// const gradientStyles = {
//   background: props.colorful
//     ? "linear-gradient(135deg, rgb(7, 167, 182), rgb(233, 177, 184))"
//     : "radial-gradient(131.26% 141.9% at 78.68% 27.52%, #5C228B 0%, rgba(112, 34, 139, 0.496875) 16.5%, rgba(131, 34, 139, 0) 100%)",
// };

// function updateGradientDegrees() {
//   rotationDegrees += 0.1; // Adjust the increment value to change the speed

//   gradientStyles.background = props.colorful
//     ? `linear-gradient(${rotationDegrees}deg, rgb(7, 167, 182), rgb(233, 177, 184))`
//     : "radial-gradient(131.26% 141.9% at 78.68% 27.52%, #5C228B 0%, rgba(112, 34, 139, 0.496875) 16.5%, rgba(131, 34, 139, 0) 100%)";

//   Object.assign(gradientElement.style, gradientStyles);
// }

// // Call the updateGradientDegrees function periodically
// setInterval(updateGradientDegrees, 100); // Adjust the interval duration to change the speed








  // Here we are creating the stars.
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const pixelCount = 200;
      const newPixels = [];

      for (let i = 0; i < pixelCount; i++) {
        const x = Math.floor(Math.random() * window.innerWidth);
        const y = Math.floor(Math.random() * window.innerHeight);
        const size = Math.floor(Math.random() * 1.5) + 1;
        const delay = Math.random() * 2;
        if (props.lightMode) {
          newPixels.push(
            <div
              key={i}
              className="pixel"
              style={{
                left: x,
                top: y,
                width: size,
                height: size,
                animation: `none`,
                opacity: 0.4,
              }}
            />
          );
        } else {
          newPixels.push(
            <div
              key={i}
              className="pixel"
              style={{
                left: x,
                top: y,
                width: size,
                height: size,
                animationDelay: `${delay}s`,
              }}
            />
          );
        }
      }

      setPixels(newPixels);
    }, 0); // set the initial delay to 1 second

    return () => clearTimeout(timeoutId);
  }, []);

  // When the animation starts, we disable the transition of the landscape elements so that conflicts with the Framer Motions can be avoided.
  const handleAnimationStart = () => {
    const elementsToDisableTransition = document.querySelectorAll(
      ".motionTrees, .motionMountains, .motionCamp"
    );

    elementsToDisableTransition.forEach((element) => {
      element.style.transition = "none";
    });
  };

  // After the Framer Motion animations are completed, we enable the transition for the landscape elements.
  const handleAnimationComplete = () => {
    const elementsToEnableTransition = document.querySelectorAll(
      ".motionTrees, .motionMountains, .motionCamp"
    );

    elementsToEnableTransition.forEach((element) => {
      element.style.transition = "";
    });
  };

  let location = useLocation();

  const generateClassName = (base, pathname) => {
    switch (pathname) {
        case "/":
            return base;
        case "/Nav1":
            return `${base}1`;
        case "/Nav2":
            return `${base}2`;
        case "/Nav3":
            return `${base}3`;
        case "/About":
            return `${base}4`;
        default:
            return "";
    }
  };

  const animationDuration = (stiffness, damping) => (Math.sqrt(stiffness) * damping) / 1000;

  // Settings
  const treesMass = 0.3;
  const treesStiffness = 20;
  const treesDamping = 5;

  const mountainsMass = 0.1;
  const mountainsStiffness = 20;
  const mountainsDamping = 5;

  const campMass = 0.7;
  const campStiffness = 30;
  const campDamping = 10;

  let treesDelay = 0.35;
  let mountainsDelay = treesDelay + animationDuration(treesStiffness, treesDamping);
  let campDelay = mountainsDelay + animationDuration(mountainsStiffness, mountainsDamping);

  // const controls = useAnimation();

  const treesControls = useAnimation();
  const mountainsControls = useAnimation();
  const campControls = useAnimation();

  useEffect(() => {
    const treesIndex = 0;
    const mountainsIndex = 1;
    const campIndex = 2;

    const animateElement = async (index, delay, controls) => {
      let stiffness, damping, mass;
      if (index === treesIndex) {
        stiffness = treesStiffness;
        damping = treesDamping;
        mass = treesMass
      } else if (index === mountainsIndex) {
        stiffness = mountainsStiffness;
        damping = mountainsDamping;
        mass = mountainsMass
      } else if (index === campIndex) {
        stiffness = campStiffness;
        damping = campDamping;
        mass = campMass
      } else {
        stiffness = 80;
        damping = 30;
      }

      console.log(`Animating element ${index} with delay ${delay} s`);

      await controls.start({
        y: 0,
        opacity: 1,
        transition: {
          type: "spring",
          // stiffness: stiffness,
          damping: damping,
          // y: { delay: delay }
          mass: mass,
          stiffness: stiffness,
          // duration: 1.2,
          // bounce: 0.15,
          delay: delay,
          opacity: props.colorful ? 0 : 1,
        },
      });

    };

    const animateSequence = async () => {
      await animateElement(treesIndex, treesDelay, treesControls);
      await animateElement(mountainsIndex, 0, mountainsControls);
      await animateElement(campIndex, 0, campControls);
      // Add more elements and delays as needed.
    };

    animateSequence();
  }, [props.colorful, treesControls, mountainsControls, campControls]);


  return (
    <div className="gradient">
      <motion.div
        className="gradient-animation"
        style={gradientStyles}
        animate={gradientAnimationControls}
        initial={{ opacity: 0 }}
      />
      <div className="start">{pixels}</div>

      {!props.colorful ? (
        <>
          <motion.div
            className={`motionTrees trees mTrees${generateClassName("", location.pathname)}`}
            custom={0}
            initial={{ y: "8vh", opacity: 0 }}
            animate={treesControls}
            transition={null}
            onAnimationStart={handleAnimationStart}
            onAnimationComplete={handleAnimationComplete}
          />

          <motion.div
            className={`motionMountains mountains mMountains${generateClassName("", location.pathname)}`}
            custom={1}
            initial={{ y: "10vh", opacity: 0 }}
            animate={mountainsControls}
            transition={null}
            onAnimationStart={handleAnimationStart}
            onAnimationComplete={handleAnimationComplete}
          />

          <motion.div
            className={`motionCamp camp mCamp${generateClassName("", location.pathname)}`}
            custom={2}
            initial={{ y: 40, x: "-50%", opacity: 0 }}
            animate={campControls}
            transition={null}
            onAnimationStart={handleAnimationStart}
            onAnimationComplete={handleAnimationComplete}
          />
        </>
        ) : ""}

      
    </div>
  );
});

export default Landscape;


// import React, { memo, useState, useEffect, useRef, useLayoutEffect } from "react";
// import { useLocation } from "react-router-dom";
// import { motion, useAnimation } from "framer-motion";
// import "./Landscape.css";

// const Landscape = memo((props) => {
//   const [pixels, setPixels] = useState([]);

//   const customGradient = {
//     background: props.colorful ? "linear-gradient(135deg, rgb(7, 167, 182), rgb(233, 177, 184))" : "radial-gradient(131.26% 141.9% at 78.68% 27.52%, #5C228B 0%, rgba(112, 34, 139, 0.496875) 16.5%, rgba(131, 34, 139, 0) 100%)",
//   }


//   // Here we are creating the stars.
//   useEffect(() => {
//     const timeoutId = setTimeout(() => {
//       const pixelCount = 200;
//       const newPixels = [];

//       for (let i = 0; i < pixelCount; i++) {
//         const x = Math.floor(Math.random() * window.innerWidth);
//         const y = Math.floor(Math.random() * window.innerHeight);
//         const size = Math.floor(Math.random() * 1.5) + 1;
//         const delay = Math.random() * 2;
//         if (props.lightMode) {
//           newPixels.push(
//             <div
//               key={i}
//               className="pixel"
//               style={{
//                 left: x,
//                 top: y,
//                 width: size,
//                 height: size,
//                 animation: `none`,
//                 opacity: 0.4,
//               }}
//             />
//           );
//         } else {
//           newPixels.push(
//             <div
//               key={i}
//               className="pixel"
//               style={{
//                 left: x,
//                 top: y,
//                 width: size,
//                 height: size,
//                 animationDelay: `${delay}s`,
//               }}
//             />
//           );
//         }
//       }

//       setPixels(newPixels);
//     }, 0); // set the initial delay to 1 second

//     return () => clearTimeout(timeoutId);
//   }, []);

//   // When the animation starts, we disable the transition of the landscape elements so that conflicts with the Framer Motions can be avoided.
//   const handleAnimationStart = () => {
//     const elementsToDisableTransition = document.querySelectorAll(
//       ".motionTrees, .motionMountains, .motionCamp"
//     );

//     elementsToDisableTransition.forEach((element) => {
//       element.style.transition = "none";
//     });
//   };

//   // After the Framer Motion animations are completed, we enable the transition for the landscape elements.
//   const handleAnimationComplete = () => {
//     const elementsToEnableTransition = document.querySelectorAll(
//       ".motionTrees, .motionMountains, .motionCamp"
//     );

//     elementsToEnableTransition.forEach((element) => {
//       element.style.transition = "";
//     });
//   };

//   let location = useLocation();

//   const generateClassName = (base, pathname) => {
//     switch (pathname) {
//         case "/":
//             return base;
//         case "/Nav1":
//             return `${base}1`;
//         case "/Nav2":
//             return `${base}2`;
//         case "/Nav3":
//             return `${base}3`;
//         case "/About":
//             return `${base}4`;
//         default:
//             return "";
//     }
//   };

//   const animationDuration = (stiffness, damping) => (Math.sqrt(stiffness) * damping) / 1000;

//   // Settings
//   const treesMass = 0.3;
//   const treesStiffness = 20;
//   const treesDamping = 5;

//   const mountainsMass = 0.1;
//   const mountainsStiffness = 20;
//   const mountainsDamping = 5;

//   const campMass = 0.7;
//   const campStiffness = 30;
//   const campDamping = 10;

//   let treesDelay = 0.35;
//   let mountainsDelay = treesDelay + animationDuration(treesStiffness, treesDamping);
//   let campDelay = mountainsDelay + animationDuration(mountainsStiffness, mountainsDamping);

//   // const controls = useAnimation();

//   const treesControls = useAnimation();
//   const mountainsControls = useAnimation();
//   const campControls = useAnimation();

//   useEffect(() => {
//     const treesIndex = 0;
//     const mountainsIndex = 1;
//     const campIndex = 2;

//     const animateElement = async (index, delay, controls) => {
//       let stiffness, damping, mass;
//       if (index === treesIndex) {
//         stiffness = treesStiffness;
//         damping = treesDamping;
//         mass = treesMass
//       } else if (index === mountainsIndex) {
//         stiffness = mountainsStiffness;
//         damping = mountainsDamping;
//         mass = mountainsMass
//       } else if (index === campIndex) {
//         stiffness = campStiffness;
//         damping = campDamping;
//         mass = campMass
//       } else {
//         stiffness = 80;
//         damping = 30;
//       }

//       console.log(`Animating element ${index} with delay ${delay} s`);

//       await controls.start({
//         y: 0,
//         opacity: 1,
//         transition: {
//           type: "spring",
//           // stiffness: stiffness,
//           damping: damping,
//           // y: { delay: delay }
//           mass: mass,
//           stiffness: stiffness,
//           // duration: 1.2,
//           // bounce: 0.15,
//           delay: delay,
//         },
//       });

//     };

//     const animateSequence = async () => {
//       await animateElement(treesIndex, treesDelay, treesControls);
//       await animateElement(mountainsIndex, 0, mountainsControls);
//       await animateElement(campIndex, 0, campControls);
//       // Add more elements and delays as needed.
//     };

//     animateSequence();
//   }, [props.colorful,treesControls, mountainsControls, campControls]);


//   return (
//     <div className={`gradient`} style={customGradient}>
//       <div className="start">{pixels}</div>

//       {!props.colorful ? (
//         <>
//           <motion.div
//             className={`motionTrees trees mTrees${generateClassName("", location.pathname)}`}
//             custom={0}
//             initial={{ y: "8vh", opacity: 0 }}
//             animate={treesControls}
//             transition={null}
//             onAnimationStart={handleAnimationStart}
//             onAnimationComplete={handleAnimationComplete}
//           />

//           <motion.div
//             className={`motionMountains mountains mMountains${generateClassName("", location.pathname)}`}
//             custom={1}
//             initial={{ y: "10vh", opacity: 0 }}
//             animate={mountainsControls}
//             transition={null}
//             onAnimationStart={handleAnimationStart}
//             onAnimationComplete={handleAnimationComplete}
//           />

//           <motion.div
//             className={`motionCamp camp mCamp${generateClassName("", location.pathname)}`}
//             custom={2}
//             initial={{ y: 40, x: "-50%", opacity: 0 }}
//             animate={campControls}
//             transition={null}
//             onAnimationStart={handleAnimationStart}
//             onAnimationComplete={handleAnimationComplete}
//           />
//         </>
//         ) : ""}

      
//     </div>
//   );
// });

// export default Landscape;