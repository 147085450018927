import React from 'react'
import ReactDOM from 'react-dom'

import { HiOutlineDotsCircleHorizontal } from 'react-icons/hi';

import './Navigation.css'

export default function Navigation(props) {
    return (
        <div className='nav-style'>
            <div className='nav-container'>
                <div className="nav-menu">
                    {props.children}
                </div>
                
                <div className="nav-button">
                    <a onClick={props.changeColorMode} className='links' style={{}}><HiOutlineDotsCircleHorizontal size="25" style={{transform: "translateY(3px)"}}/></a>
                </div>
            </div>
        </div>
    )
}