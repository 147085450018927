import { React, useState, useEffect } from 'react'

import { motion, useAnimation, AnimatePresence } from "framer-motion"

import './Nav1.css'

export default function Nav1() {
    const [isOpen, setIsOpen] = useState(false)

    const global = {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    }

    return (
        <div style={global}>
            <motion.div 
                className="expandedSound" 
                style={{ overflow: "hidden", borderRadius: "5px", boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}
                onClick={() => setIsOpen(!isOpen)} 
                transition={{ layout: { duration: 0.4, type: "spring" }}}
                layout>
                <motion.h4 layout="position" style={{ margin: "0" }}>Framer Motion 🚀</motion.h4>
                {isOpen && (
                    <motion.div 
                        className="expanded"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.2 }}>
                        <p>
                            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eos suscipit repudiandae deleniti unde dolor delectus modi dicta necess itati tempora itaque.
                        </p>    
                        <p>
                            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eos suscipit rs modi dicta necess itati temporue?
                        </p> 
                        <button>Minimize</button>
                    </motion.div>
                )}
            </motion.div>
        </div>
    )
}
