import React, {useState, useEffect} from "react"

import { motion, useAnimation } from "framer-motion";

import Volume from './Volume.js'
import './Soundvolume.css'

export default function Soundvolume() {


    return (
        <div className='volume-container'>
            <Volume volume={0.3} mark={15} width={1} height={3} fullWidth={true} id={1} modifyVolume={6}></Volume>
        </div>
    )
}