import React, {useEffect, useState} from 'react'
import Draggable, {DraggableCore} from 'react-draggable';

import './Volume.css'

export default function Volume(props) {
    useEffect(() => {
        // console.log(props.database[1])
    }, [props])
    
    const volumeContainer = {
        width: props.fullWidth ? '100%' : props.width + 'px',
        height: props.height + 'px',
        border: '0px solid var(--main-color)',
        borderRadius: '2px',
        cursor: 'pointer',
        background: 'rgba(217, 217, 217, 0.5)'
    }    

    const volumeProgress = {
        width: props.volume*100 + '%',
        height: '100%',
        background:'rgba(217, 217, 217, 0.5)',
        borderRadius: '2px',
    }

    const volumeMark = {
        // transform: 'translateX(0px, -4.5px)',
        width: props.mark + 'px',
        height: props.mark + 'px'
    }

    const eventLogger = (e, data) => {
        // console.log('Event: ', e)
        var realX = data.x + 5
        console.log('Volume: ', realX.toFixed(3))
        console.log(props.volume.toFixed(3))
        console.log(realX.toFixed(3))
        props.modifyVolume(props.id, realX)
    }

    useEffect(() => {
        // console.log("This item called "+props.item+" has a volume of: "+props.volume.toFixed(3))
    }, [props.volume])

    // ΕΔΩ ΚΑΠΟΥ ΠΡΕΠΕΙ ΝΑ ΦΤΙΑΞΩ ΤΟ CLICK EVENT ΠΟΥ ΘΑ ΒΛΕΠΕΙ ΣΕ ΠΟΙΟ ΣΗΜΕΙΟ ΤΟΥ DIV ΠΑΤΑΕΙ, ΘΑ ΚΑΝΕΙ ΠΡΑΞΕΙς ΚΑΙ ΘΑ ΟΡΙΖΕΙ ΤΟ ΝΈΟ ΠΟΣΙΤΙΟΝ ΤΟΥ DRAGGABLE MARKER
    return (
        <div style={volumeContainer} className='volume-container'>
            <div style={volumeProgress} className='volume-progress'>
            <Draggable
                axis="x"
                bounds={{top: 0, left: -5, right: props.width-(props.mark)/2, bottom: 0}}
                position={{x: (props.volume*100-(props.mark)/2), y: -(props.mark - props.height)/2}}
                scale={1}
                onDrag={eventLogger}
                allowAnyClick={false}
                // onStop={this.handleStop}
                // position={null}
                grid={[2.5, 2.5]}
                // onStart={this.handleStart}
                // handle=".handle"
                >
                <div style={volumeMark} className='mark'></div>
            </Draggable>
            </div>
        </div>
        
    )
}