// https://www.youtube.com/watch?v=59IXY5IDrBA
// https://reactrouter.com/docs/en/v6/getting—started/overview
// https://bobbyhadz.com/blog/react-get-current-url
// https://www.copycat.dev/blog/react-chrome-extension/
import { Routes, Route, Link, useLocation } from "react-router-dom";

import  React, { useEffect, useState } from 'react'

import { Helmet } from 'react-helmet-async';

// import ReactGA from 'react-ga';

// ReactGA.initialize('G-4DSYXEQTN0');

import './App.css';

import { ReactComponent as Logo } from './logos/ypnoss_1.svg';

import Landscape from './components/Landscape.js'

import Navigation from './components/Navigation'
import Mainview from './components/Mainview'
import Controlnav from './components/Controlnav'
import Filters from './components/Filters'
import ModalContainer from './components/ModalContainer';


// import Cplayer from './components/Cplayer'

import Sounds from './pages/Sounds.js'
import Nav1 from './pages/Nav1.js'
import Nav2 from './pages/Nav2.js'
import Nav3 from './pages/Nav3.js'
import About from './pages/About.js'
import Error from './pages/Error.js'

import Videobackground from './components/Videobackground.js'

import Filtcat from './data/filters.js'

import Mastervolume from './components/Mastervolume'


import { AiOutlinePause } from 'react-icons/ai';
import { SlControlPlay } from 'react-icons/sl';
import { BsMoonStars } from 'react-icons/bs';
import { TfiTimer } from 'react-icons/tfi';

// https://www.freecodecamp.org/news/how-to-use-localstorage-with-react-hooks-to-set-and-get-items/

function App() {
  const [searchQ, setSearchQ] = useState("")
  const [searchQterm, setSearchQterm] = useState("")
  const [activeId, setActiveId] = useState(Filtcat[0].id)
  const [activeName, setActiveName] = useState(Filtcat[0].name)

  const [colorMode, setColorMode] = useState(true)

  const [dataActivateSound, setDataActiveSound] = useState([
    {
      id: 0,
      dbid: 0,
      title: "",
      volume: 0,
      play: "false",
      url: "",
      isactive: false,
      pause: false
    }
  ])
  
  function addSoundToDB (soundId, soundTitle, soundVolume, soundState, soundUrl, soundActive, soundPause) {
    const updateData = [
      ...dataActivateSound,
      {
        id: dataActivateSound.length,
        dbid: soundId,
        title: soundTitle,
        volume: soundVolume,
        play: soundState,
        url: soundUrl,
        isactive: soundActive,
        pause: soundPause
      }
    ];
    
    setDataActiveSound(updateData);
  }

  function removeSoundFromDB (sid) {
    console.log(sid)
    var requiredLengthOfDB = dataActivateSound.length-1

    var trueID = 0

    dataActivateSound.forEach((activeSound) => {
      if (sid == activeSound.dbid) {
        trueID = activeSound.id
      }       
    })

    console.log("$ The length of the database is:  " + requiredLengthOfDB)
    console.log("$ The sound with name " + dataActivateSound[trueID].title + " and sid of '" + sid + "' is stored in the databse in the position " + trueID)

    if (trueID < requiredLengthOfDB) {
      const updatedDB = dataActivateSound.map((activeSound, index) => {
        console.log("$ Index: " + index)
        console.log("$ Id to be removed: " + sid)

        console.log(activeSound.id + " > " + trueID)
        if (activeSound.id > trueID) {
          console.log("$   We are evaluating sound from db with id: " + (index))
          let tempID = activeSound.id-1
          console.log(tempID)
          return {
            ...activeSound,
            id: tempID,
          }
        } else {
          return activeSound
        }
      })

      console.log(updatedDB)
      setDataActiveSound(updatedDB);
    }

    setDataActiveSound(current =>
      current.filter(sound => {
        console.log("= = = = = = = = = =")
        console.log("ΕΛΕΓΧΟΥΜΕ ΤΟΝ ΗΧΟ ΜΕ ID: "+sound.dbid)
        console.log("ΠΡΕΠΕΙ ΝΑ ΑΦΑΙΡΕΘΕΙ Ο ΗΧΟΣ ΜΕ ID: "+ sid)
          return sound.dbid !== sid
      })
    )    
  }

  const changeColorMode = () => {
    setColorMode(!colorMode)
  }

  // Μέσω του Localstorage αποθηκεύουμε και ανακτούμε την υπάρσουχα λίστα
  useEffect(() => {
    const playlist = JSON.parse(localStorage.getItem('dataActivateSound'));
    if (playlist) {
      console.log(playlist)
      const updateData = 
        playlist
      ;
      
      setDataActiveSound(updateData);
    }
  },[])

  const changeActiveId = (e) => {
    if (e === 999) {
      setActiveName("userInput")
    } else {
      setActiveId(e)
      setActiveName(Filtcat[e-1].name)
    }
  }

  const createPlaylist = () => {
    // let temp = JSON.stringify(dataActivateSound)
    // console.log(temp)
    
    let updatedArray = dataActivateSound.map(sound => {
      return {
        ...sound,
        pause: sound.pause = true
      };
    });

    console.log(updatedArray)

    localStorage.setItem('dataActivateSound', JSON.stringify(updatedArray))
  }

  const clearPlaylist = () => {
    localStorage.clear('dataActivateSound', JSON.stringify(dataActivateSound))
  }

    const deleteActivePlaylist = () => {
      setMasterPlayPause({ ...masterPlayPause, state: true})
      dataActivateSound.splice(1)
    }

  const [masterVolume, setMasterVolume] = useState({
      volume: 0.8,
  })

  const [masterPlayPause, setMasterPlayPause] = useState({
    state: false,
  })

  const modifyMasterVolume = (value) => {
    setMasterVolume({ ...masterVolume, volume: value})
  }

    const masterPausePlay = (e) => {
      console.log(e)

      const updatedItems = dataActivateSound.map(item => {
        item.pause = !item.pause;
        return item;
      })

      console.log(updatedItems)
    }

    const modifyActiveState2 = () => {
      const updatedItems = dataActivateSound.map(item => {
        item.isactive = true
        return item
      })

      console.log(updatedItems)
      setDataActiveSound(updatedItems)
    }
  
    const modifyActiveState = (id) => {
      console.log("The is active state is evaluated with sound id: "+id)
      // if (dataActivateSound[id].isactive == undefined) {
        const updatedDB = dataActivateSound.map(sound => {
          console.log(sound)
          if (sound.dbid == id) {
            // if (sound.isactive)
            return {
              ...sound,
              isactive: true,
            }          
          } else {
            return sound
          }
            
        })
  
        console.log(updatedDB)
        setDataActiveSound(updatedDB);
    }

  const modifyMasterPlay = () => {
    console.log(masterPlayPause.state)
    setMasterPlayPause({ ...masterPlayPause, state: false})

    const updatedItems = dataActivateSound.map(item => {
      item.pause = false;
      return item;
    })

    setDataActiveSound(updatedItems)
  }

  const modifyMasterPause = () => {
    console.log(masterPlayPause.state)
    setMasterPlayPause({ ...masterPlayPause, state: true})

    const updatedItems = dataActivateSound.map(item => {
      item.pause = true;
      return item;
    })

    setDataActiveSound(updatedItems)
  }

  const modifyPauseState = (id) => {
    var soundToBePaused

    const updatedDB = dataActivateSound.map(sound => {
      if (sound.dbid == id) {
        console.log("Play: "+sound.play)
        console.log("Pause: "+sound.pause)
        if (sound.play == true && sound.pause == false) {
          return {
            ...sound,
            pause: true,
          }          
        } else if (sound.play == true && sound.pause == true) {
          return {
            ...sound,
            pause: false,
          }
        }       
      } else {
        return sound
      }  
        
    })

    setDataActiveSound(updatedDB);
  }

  const modifyVolume = (id, newValue) => {
    console.log(id)
    console.log((newValue/100).toFixed(3))

    const updatedDB = dataActivateSound.map(sound => {
      if (sound.id == id) {
        // if (sound.isactive)
        return {
          ...sound,
          volume: (newValue/100),
        }          
      } else {
        return sound
      }
        
    })

    setDataActiveSound(updatedDB);
  }

  // 👇️ with React router
  let location = useLocation();
  
  function activateSound() {
    console.log("start from app")
  }

  const getSearch = (userInput) => {
    console.log("User Input: " + userInput)
    
    // setSearchQ(searchResult)
    setSearchQterm(userInput)

    // if (userInput === "") {
    //   setSearchQ([])
    // }
  }

  const [expandShadow, setExpandShadow] = useState(false)

  const triggerExpandShadow = (event) => {
    setExpandShadow(event)
  }
  
  return (
    <div className="App">
      

      <Landscape lightMode={true} colorful={colorMode}/>
      {/* <Videobackground/> */}

      <Navigation changeColorMode={changeColorMode}>
        <Link to="/" className='logo' style={{fontSize: "21px", padding: "15px"}}><Logo width="5rem" style={{filter: "contrast(0.8)",transform: "translateY(9px)", padding: "2px 0 0px 10px"}}/></Link>
        {/*<Link to="/" className='links'>Home</Link>*/}
        {/* <Link to="/Nav1" className='links'>Nav 1</Link> */}
        <Link to="/Nav2" className='links'>My Mixes</Link>
        <Link to="/Nav3" className='links'>Notes</Link>
        <Link to="/About" className='links'>About</Link>
      </Navigation>
      
      <Mainview>         
        { location.pathname == '/' ? (
          <Filters 
            data={Filtcat} 
            changeActiveId={changeActiveId} 
            activeId={activeId} 
            getSearch={getSearch}
            expandShadow={expandShadow}
            triggerExpandShadow={triggerExpandShadow}/>
          ) : ("") }
        
        <Routes>
          <Route path="/" element={<Sounds searchQ={searchQ} searchQterm={searchQterm} addSoundToDB={addSoundToDB} database={dataActivateSound} modifyisactive={modifyActiveState} removeSoundFromDB={removeSoundFromDB} modifyVolume={modifyVolume} activeName={activeName} triggerExpandShadow={triggerExpandShadow}/>} />
          <Route path="/Nav1" element={<Nav1 />} />
          <Route path="/Nav2" element={<Nav2 />} />
          <Route path="/Nav3" element={<Nav3 />} />
          <Route path="/About" element={<About />} />
          <Route path="/*" element={<Error />} />
        </Routes>
      </Mainview>

      <Controlnav database={dataActivateSound} modifyPausePlay={masterPausePlay} removeSoundFromDB={removeSoundFromDB} modifyPauseState={modifyPauseState} modifyVolume={modifyVolume} masterVolume={masterVolume.volume} masterPlayPause={masterPlayPause.state} clear={deleteActivePlaylist}>              
          <div className="player-controls">
            <AiOutlinePause className="button" style={{fontSize: '26px'}} onClick={modifyMasterPause}/>
            <SlControlPlay className="button" style={{fontSize: '18px'}} onClick={modifyMasterPlay}/>
            <Mastervolume volume={masterVolume.volume} mark={15} width={200} height={3} fullWidth={false} id={1} modifyMasterVolume={modifyMasterVolume}/>
            <TfiTimer className="button" style={{fontSize: '18px'}} onClick={clearPlaylist}/>
            <BsMoonStars className="button" style={{fontSize: '18px'}} onClick={createPlaylist}/>
          </div>
      </Controlnav>

     {/* <ModalContainer id="shadowModal">
      </ModalContainer>*/}
    </div>
  );
}

export default App;
