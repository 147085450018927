import React from 'react'

import { Checkbox } from 'antd';

export default function Nav3() {
    const flex = {
    //    display: 'flex',
       flexDirection: 'column',
       color: "white"
    }

    const ligthMode = {
       color: "white"
    }

    const onChange = (e) => {
        console.log(`checked = ${e.target.checked}`);
    }

    return (
        <div style={flex}>
            <div>Notes</div>
            <Checkbox onChange={onChange} style={ligthMode}>Να φτιάξω τα φίλτρα. Στο Filtrers.js εμφανίζει τα φίλτρα μέσω του Sound.js μέσω map.</Checkbox>
            <Checkbox onChange={onChange} style={ligthMode}>Το active state να δω πρώτα.</Checkbox>
            <Checkbox onChange={onChange} style={ligthMode} checked>Να βάλω ένα κουμπί που να κλείνει όλα τα sounds.</Checkbox>
            <Checkbox onChange={onChange} style={ligthMode}>hypsoo​.org, e-levare.org, evare.org</Checkbox>
            <Checkbox onChange={onChange} style={ligthMode}>Μετά από αποθηκευμένο playlist, δεν απενεργοποιούντε τα sounds. Πρέπει να πατήσεις ένα νέο και μετά να πατήσεις delete από το control manager.</Checkbox>
            <Checkbox onChange={onChange} style={ligthMode}>https://www.dunebook.com/best-react-animation-libraries/</Checkbox>
            <Checkbox onChange={onChange} style={ligthMode}>http://maisano.github.io/react-router-transition/getting-started</Checkbox>
            <Checkbox onChange={onChange} style={ligthMode}>Να φτιάξω το background να αλλάζει με βάση την ώρα του χρήστη και να μπει ως κουμπάκι κάπου.</Checkbox>
            <Checkbox onChange={onChange} style={ligthMode}>Settings, να υπαρχει κουμπι για light version</Checkbox>
            <Checkbox onChange={onChange} style={ligthMode}>https://namelix.com/app/?keywords=elevation</Checkbox>
            <Checkbox onChange={onChange} style={ligthMode}>https://huggingface.co/spaces/SemanticTypography/Word-As-Image</Checkbox>
            <Checkbox onChange={onChange} style={ligthMode}>vigilo​.ME, ypnoss.com, epnoss.com, oneiroo.com, ipnos, eidos</Checkbox>
            <Checkbox onChange={onChange} style={ligthMode}>
            There are several websites where you can find high-quality nature and ASMR sounds that are free for commercial use. Here are some suggestions:

            FreeSound: This is a community-driven platform where users can upload and share sounds under Creative Commons licenses. You can search for specific sounds, filter by license type, and download them for free.

            SoundBible: This website offers thousands of free sound effects and royalty-free music tracks that you can use in your projects. You can browse by category, keyword, or license type and download the files in MP3 or WAV format.

            YouTube Audio Library: This is a collection of high-quality music and sound effects that you can use in your videos and other projects. You can search for specific tracks or browse by genre, mood, or instrument.

            Zapsplat: This website offers over 68,000 free sound effects and music tracks that you can use in your projects. You can search for specific sounds, browse by category, or filter by license type.

            Nature Sounds: This website offers a collection of high-quality nature sounds, including rain, ocean waves, thunderstorms, and more. You can listen to the sounds online or download them in MP3 format.

            Remember to check the license type for each sound you download to ensure it is suitable for commercial use, and always give credit to the creator if required.
            Mixkit, which has 54 free nature sound effects, including waves, rain, thunder, birds, jungle, volcano, and more.TunePocket, which has 240 asmr sounds, including water drops, clock ticking, scratching, chewing, breathing, and more.Chosic, which has 41 free nature sounds and music, including ocean, bird, forest, fire, and more.

            </Checkbox>
        </div>
    )
}
