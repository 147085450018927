// https://www.npmjs.com/package/write-json-file
// https://openbase.com/js/react-player
// https://shareg.pt/szDcBoO
import React, {useState, useEffect, useRef} from "react"

import { motion, useAnimation } from "framer-motion";

import Volume from './Volume'

import Cplayer from '../components/Cplayer'
import MaterialDesignComponent from "../components/MaterialDesignComponent";

import { HiOutlinePause } from 'react-icons/hi';
import { HiOutlinePlay } from 'react-icons/hi';
import { IoIosRemove } from 'react-icons/io';
import { BsMusicNoteList } from 'react-icons/bs';

import './Controlnav.css'

export default function Controlnav(props) {
    const [listDisplay, setListDisplay] = useState(false)
    const [playlistHeight, setPlaylistHeight] = useState(0)
  
    // const playlistHeight = 0;

    useEffect(() => {
        const playlist = document.getElementById('activeSounds')
        let numberOfActiveSounds = Array.from(playlist.children).length - 1;

        const playlistItemHeight = document.getElementById('activeSounds').firstChild.offsetHeight
        console.log(playlistItemHeight)

        const playlistClearHeight = document.getElementById('activeSounds').lastChild.offsetHeight
        console.log(playlistClearHeight)

        setPlaylistHeight((numberOfActiveSounds * playlistItemHeight) + 19)

        console.log("The playlist is open: " + listDisplay)
        console.log(document.getElementById('activeSounds').offsetHeight)
        console.log(numberOfActiveSounds);
        console.log(playlist)

    }, [props, listDisplay]);


    const customExpansionList = {
        display: listDisplay ? "block" : "",
        opacity: listDisplay ? "1" : "0",
        position: "absolute",
        width: listDisplay ? "200px" : "70px",
        height: listDisplay ? `${playlistHeight}px` : `60px`,
        background: listDisplay ? "rgba(255, 255, 255, 1)" : "",
        boxShadow: listDisplay ? "0px 4px 4px rgba(0, 0, 0, 0.25)" : "",
        borderRadius: "6px",
        transform: listDisplay ? `` : "",
        transition: listDisplay ? "all 200ms ease-in-out 0s" : "all 200ms ease-in-out 0s",
        padding: listDisplay ? "20px" : "0",
        zIndex: listDisplay ? "22" : "0",
    }

    const customExpansionContentList = {
        display: listDisplay ? "block" : "none",
    }

    const customExpansionContentX = {
        display: listDisplay ? "block" : "none",
        cursor: "pointer", 
        width: "fit-content"
    }

    const customNoContent = {
        fontSize: "12px",
        display: listDisplay ? "flex" : "none",
        justifyContent: "initial",
        position: "relative",
        top: "3px"
    }

    // Controlnav.js receives the following props: 
    // database              => Gets the database from App.js
    // modifyPausePlay       => 
    // removeSoundFromDB     => 
    // modifyPauseState      => 
    // modifyVolume          => 
    // masterVolume          => 
    // masterPlayPause       => 
    // clear                 => 

    const data = props.database.slice(1).map(item => {
        // Console.log the volume of each sound up to three decimals
        console.log(item.volume.toFixed(3))
        var playingState

        console.log("Master Play/Pause:" + props.masterPlayPause)
    
        if (item.play == true && item.pause == false) {
            playingState = true
        } else if (item.play == true && item.pause == true) {
            playingState = false
        }

        return (
            <motion.div
                animate={{ opacity: listDisplay ? 1 : 0 }}
                transition={{ type: "tween", duration: 0.05, delay: listDisplay ? 0.3 : 0 }}
                key={item.id} 
                style={customExpansionContentList}>
                    <div style={{fontSize: "10px", fontWeight: "600"}}>Tile: {item.title} </div>
                    <div style={{fontSize: "10px"}}>Volume: {item.volume.toFixed(3)} </div>
                    <div style={{fontSize: "10px"}}>State: {((playingState) ? 'Active' : 'Inactive')} </div>
                    <div style={{display: "inline", cursor: "pointer"}} onClick={() => props.modifyPauseState(item.dbid)}>
                        { playingState ? (<HiOutlinePause/>) : (<HiOutlinePlay/>) }
                    </div>
                    <div style={{display: "inline", cursor: "pointer"}} onClick={() => props.removeSoundFromDB(item.dbid)}><IoIosRemove/></div>
                    <Volume volume={props.database[item.id].volume} item={props.database[item.id].title} mark={10} width={100} height={2} fullWidth={false} id={item.id} modifyVolume={props.modifyVolume}></Volume>
                
                    <Cplayer src={item.url} volume={item.volume*(props.masterVolume)} playing={playingState} />
            </motion.div>
        )
    })

    function displayList(event) {
        

        if (listDisplay === false) {
            setListDisplay(true)
            console.log("Control menu is opened")
        } else {
            setListDisplay(false)
            console.log("Control menu is closed")
        }
    }

    const clearPlayList = () => {
        props.clear()
        setPlaylistHeight(70)
    }

    return (
        <motion.div 
            initial={{ y: 350, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 1, type: "spring", bounce: 0.15, delay: 1.8 }}
            className='control-style'>
            <div className='control-container'>
                {props.children}             
                <div style={customExpansionList} id='activeSounds'>
                    {data}

                    { props.database.length === 1 ? 
                        (
                            <motion.div
                                style={customNoContent}
                                animate={{ opacity: listDisplay ? 1 : 0 }}
                                transition={{ type: "tween", duration: 0.05, delay: listDisplay ? 0.16 : 0 }}
                                exit={{ opacity: 0, duration: 0 }}>
                                No active sound
                            </motion.div>
                        ) 
                        : "" }

                    { props.database.length > 1 ? (
                        <motion.div 
                        animate={{ opacity: listDisplay ? 1 : 0 }}
            transition={{ type: "easeIn", duration: 0.05, delay: listDisplay ? 0.3 : 0 }}
                            style={customExpansionContentX} 
                            onClick={() => clearPlayList()}>x
                        </motion.div>
                        ) : ("") }
                </div>

                <motion.div 
                    onClick={displayList}
                    className='playlist-button'
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 3.7, ease: "easeInOut", duration: 0.1 }}>
                    <BsMusicNoteList className='control-panel' />
                </motion.div>
                {/*<MaterialDesignComponent>
                    <p>.</p>
                </MaterialDesignComponent>*/}
            </div>
        </motion.div>
        // https://ant.design/components/time-picker
    )
}