import React, { useState, useEffect, useRef } from "react";
import { motion, useAnimation, AnimatePresence } from "framer-motion";
import { HiOutlineInformationCircle } from "react-icons/hi";
import { MdFavoriteBorder } from "react-icons/md";
import ExpendableCard from "../components/ExpendableCard";
import "./Sound.css";

// Sounds.js receives the following props - το soundState είναι το τοπικό DB του Sound.js: 
// id                       => Χρησιμοποιείται στο setSoundState για να αποθηκεύσει το id του
// sound                    => Χρησιμοποιείται σε διάφορα σημεία εντός του component για να αποθηκεύσει ή έμφασνίσει την ονομασία του ήχου
// state(-)                 => Θεωρώ ότι δεν κάνει τίποτα και είναι προς διαγραφή
// category                 => Χρησιμοποιείται μόνο μια φόρά για να ελεγχθεί αν ο ήχος που πληρκτρολόγησε ο χρήστης έχει κάποια σχέση με κατηγορία ενός άλλου ήχου (οταν γίνεται 1η φορά πληκτρολ.)
// dconnection              => Χρησιμοποιείται μόνο έχει ήδη πληκτρολ. κάτι και ψάχνουμε να βρούμε αν είναι direct connected
// indconnection            => Χρησιμοποιείται μόνο έχει ήδη πληκτρολ. κάτι και ψάχνουμε να βρούμε αν είναι indirect connected
// description              => Χρησιμοποιείται μόνο στο modal content 
// volume                   => Χρησιμοποιείται στο setSoundState για να αποθηκεύσει το volume του
// isactive                 => Χρησιμοποιείται πρώτη φορά όταν ο ήχος είναι active για να ενεργοποιήσει το setSoundState - στο playsound και με classes
// addSoundToDB*            => Χρησιμοποιείται όταν ο ήχος έχει μόλις δημιουργηθεί μέσω του του soundState 
// removeSoundFromDΒ*       => Χρησιμοποιείται στο playSound και αν ειναι active τότε delete από την βάση
// modifyisactive(-)        => Δεν χρησιμοποιείται πουθενά
// activeName               => Χρησιμοποιείται για να εμφανίσει περιεχόμενο μέσω searchQterm
// searchQ                  =>  
// searchQterm              => 
// lengthOf                 => Χρησιμοποιείται για να βάλει class στα relative μέσα στον κώδικα
// firstMount               => Χρησιμοποιείται για να μην εμανίσει τίποτα στο 2ο load και για animation
// tempCountDirectSounds    => 
// tempCountRelativeSounds  => 
// tempCountHiddenSounds    => 
// countRelative            => 
// countDirect              => 
// countHidden              => 
// triggerExpandShadow      => 

export default function Sound(props) {
  const [soundState, setSoundState] = useState({
    id: 0,
    playing: false,
    volume: 1,
    justcreated: true,
  });
  const [countSearchItems, setCountSearchItems] = useState(0);
  const [animation, setAnimation] = useState(false);
  const [hoverTime, setHoverTime] = useState(0);

  const [isExpanded, setIsExpanded] = useState(false);


  const isHovering = useRef(false);
  const myRef = useRef(null);

  useEffect(() => {
    if (props.isactive) {
      setSoundState({
        ...soundState,
        playing: true,
        id: props.id,
        volume: props.volume,
      });
    }
  }, []);

  useEffect(() => {
    if (!soundState.justcreated) {
      props.addSoundToDB(
        soundState.id,
        props.sound,
        soundState.volume,
        soundState.playing,
        `../sounds/${props.sound}.mp3`,
        true,
        false
      );
    }
  }, [soundState]);

  function playSound() {
    if (!props.isactive) {
      setSoundState({
        ...soundState,
        justcreated: false,
        playing: true,
        id: props.id,
        volume: props.volume,
        // isactive: true,
      });
    } else if (props.isactive) {
      setSoundState({
        ...soundState,
        justcreated: true,
        playing: false,
        id: 0,
        volume: props.volume,
        // isactive: false,
      });
      props.removeSoundFromDB(soundState.id);
    }
  }

  useEffect(() => {
    const classes = myRef.current.classList;
    const stringified = JSON.stringify(classes);

    const hasRelativeClass = stringified.includes("relative");
    const hasHiddenClass = stringified.includes("hide");
    const hasImportantClass = stringified.includes("importantDisplay");
    const isRelatedTag = stringified.includes("dispnone");

    if (hasRelativeClass) {
      props.countRelative();
    } else if (!hasRelativeClass && !hasHiddenClass) {
      props.countDirect();
    } else if (hasHiddenClass && !hasImportantClass && !isRelatedTag) {
      props.countHidden();
    }

    if (props.searchQterm.length === 0) {
      props.countRelative(0);
      props.countDirect(0);
      props.countHidden(0);
    }
  }, [props.searchQterm]);

  useEffect(() => {
    if (!props.firstMount) {
      setAnimation(true);
      const timeoutId = setTimeout(() => {
        setAnimation(false);
      }, 310);
      return () => clearTimeout(timeoutId);
    }
  }, [props.searchQterm]);

  const displayId = () => {
    if (!isHovering.current) {
      console.log(props.sound + " is hover ");
      isHovering.current = true;
      handleMouseOver();
    }
  };

  const handleMouseLeave = () => {
    isHovering.current = false;
  };

  const handleMouseOver = () => {
    const startTime = Date.now();
    const intervalId = setInterval(() => {
      setHoverTime(Date.now() - startTime);
    }, 10);

    myRef.current.addEventListener("mouseleave", () => {
      clearInterval(intervalId);
      setHoverTime(0);
    });
  };

  const { innerWidth, innerHeight } = window;

  useEffect(() => {
    console.log(innerWidth);
    console.log(innerHeight);
  }, [innerWidth, innerHeight]);

  const [posL, setPosL] = useState()
  const [posR, setPosR] = useState()
  const [posB, setPosB] = useState()
  const [posDifX, setPosDifX] = useState()
  const [posDifY, setPosDifY] = useState()

  useEffect(() => {
    console.log(posL)
    console.log(posR)
  }, [posL, posR]);

  const onClick = (event) => {

    const classNameSVG = event.target.baseVal;
    const classNameDIV = event.target.className;
    const idDIV = event.target.id;
    const tagName = event.target.tagName;
  
    console.log(event.target);
    console.log(classNameSVG);
    console.log(classNameDIV);
    console.log(idDIV);
    console.log(event.target.tagName);

    const element = event.target.parentElement;
    const position = element.getBoundingClientRect();

    const width = window.getComputedStyle(event.target).getPropertyValue('width');
    console.log(width)    

    console.log(idDIV === "expandedCard")

    if (classNameSVG === undefined && tagName !== "DIV" || tagName === "DIV" && !classNameDIV.includes("activateContent") && classNameDIV.includes("moreButton") && width !== "500px") {
      console.log("prevent click")
      console.log(classNameDIV)
      // console.log(element);
      console.log(position);
      // console.log(position.right - position.left);
      setPosL(position.left)
      setPosR(position.right)
      setPosR(position.bottom)
      setPosDifX(innerWidth - position.right)
      setPosDifY(innerHeight - position.bottom)

      changeIsExpanded();
    } 

    if (tagName === "DIV" && idDIV === "expandedCard" && width !== "500px") {
      console.log(idDIV)
      playSound();
      props.justClicked(!soundState.playing)
    } else if (tagName === "BUTTON" && classNameDIV.includes("activateContent")) {
      playSound();
      props.justClicked(!soundState.playing)
    }else if (tagName === "BUTTON" && classNameDIV.includes("moreButton")) {
      console.log("tagName => " + tagName)
      // console.log(idDIV)
      // console.log(classNameDIV)

      // console.log(element);
      console.log(position);
      // console.log(position.right - position.left);
      setPosL(position.left)
      setPosR(position.right)
      setPosR(position.bottom)
      setPosDifX(innerWidth - position.right)
      setPosDifY(innerHeight - position.bottom)

      changeIsExpanded();
    }
  };

  const changeIsExpanded = () => {
    console.log("Testtttttttttttttttttttttttttttttttttttttttttttttttttt")
    setIsExpanded(!isExpanded)

    if (!isExpanded) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
    // document.body.classList.toggle("modal-open")
    props.triggerExpandShadow(!isExpanded)
    console.log("=> "+ !isExpanded)
  }

  const customExpansion = {
    display: isExpanded ? "block" : "",
    opacity: isExpanded ? "1" : "0",
    position: "absolute",
    width: isExpanded ? "500px" : "56px",
    height: isExpanded ? "350px" : "56px",
    background: isExpanded ? "rgba(255, 255, 255, 1)" : "",
    boxShadow: isExpanded ? "0px 4px 4px rgba(0, 0, 0, 0.25)" : "",
    borderRadius: "12px",
    transform: isExpanded ? `translate(calc(-50vw + 28px + ${posDifX}px - 10px), calc(-50vh + 28px + ${posDifY}px - 0px))` : "",
    transition: "all 300ms ease-in-out 0s",
    padding: isExpanded ? "20px" : "0",
    zIndex: isExpanded ? "22" : "0",
    cursor: isExpanded ? "initial" : "pointer"
  }

  const customExpansionContent = {
    display: isExpanded ? "block" : "none",
  }

  const [triggetAnimation, setTriggetAnimation] = useState(false);

  useEffect(() => {
    setTriggetAnimation(true)

    // Use the useEffect hook to monitor changes in props.activeName
    if (props.activeName) {
      // When props.activeName becomes true, trigger the animation
      const animationTimeout = setTimeout(() => {
        setTriggetAnimation(false);
      }, 1000); 
      return () => clearTimeout(animationTimeout);
    }
  }, [props.activeName]);

  return (
    <motion.div
      initial={{ opacity: 0, y: 0 }}
      animate={{ opacity: 1, y: "0px" }}
      transition={{ delay: 1.8, duration: 0.3 }}
      onMouseOver={displayId}
      onMouseLeave={handleMouseLeave}
      // ${isExpanded ? 'enlarged' : ''}

      ref={myRef}
      id="ypnosSound"
      className={`
        sound-style
        

        ${isExpanded ? 'enlarged' : ''}

        ${hoverTime >= 200 ? ("pressed") : ("") }

        ${props.isactive ? ("active") : ("")} 
       
       
        
        ${props.firstMount ? ("firstLoad") : ("")}

        ${(() => {
          if (props.searchQterm.length === 0) {
            if (props.activeName === 'All') {
              return 'importantDisplay'
            } 
          } 
          return ''
        })()}

        ${(() => {
          //ΜΠΑΙΝΕΙ ΕΔΩ ΜΟΝΟ ΟΤΑΝ ΔΕΝ ΕΧΕΙ ΠΛΗΚΤΡΟΛΟΓΗΘΕΙ ΤΙΠΟΤΑ
          if (props.searchQterm.length === 0) {
            // ΑΝ Ο ΗΧΟΣ ΕΧΕΙ ΩΣ ΜΙΑ ΑΠΟ ΤΙΣ ΚΑΤΗΓΟΡΙΕΣ ΤΟΥ ΤΟ ΕΝΕΡΓΟ ΕΠΙΛΕΓΜΕΝΟ ΦΙΛΤΡΟ, ΤΟΤΕ ΔΕΝ ΓΙΝΕΤΑΙ ΤΠΤ, ΑΛΛΙΩΣ ΤΟ ΚΡΥΒΟΥΜΕ.
            if (props.category.includes(props.activeName)) {
              return ''              
            } else {
              return 'hide'
            }
          } else {
            if (props.dconnection.includes(props.searchQterm.toLowerCase())) {
              return ''
            } else if (props.indconnection.includes(props.searchQterm.toLowerCase())) {
              return 'relative'
            } else {
              return 'hide'
            }
          }
        })()}

        ${props.sound === "Reccomended" ? ("dispnone") : ("")} 
        
        ${props.sound === "Reccomended" && props.tempCountRelativeSounds !== 0 ? ("disp") : ("")} 
        
        ${props.sound === "Reccomended" && props.tempCountSearchItems === props.lengthOf ? ("hiderel") : ("")} 
      `}
      onClick={onClick}>
        <span className={`activeSoundContent `} style={{fontSize: "10px", display: "block", textAlign: "center"}}>
          {props.sound.charAt(0).toUpperCase() + props.sound.slice(1).replace(/([A-Z])/g, m => " " + m)}
          {/*IoMdBonfire*/}
          {/*WiRainMix*/}
          {/*GiForestCamp*/}
        </span>
        <div id="expandedCard" style={customExpansion}>
          <motion.div 
            className="content" 
            style={customExpansionContent}
            animate={{ opacity: isExpanded ? 1 : 0 }}
            transition={{ type: "tween", duration: 0.05, delay: isExpanded ? 0.28 : 0 }}>
            <div className="titleContent" style={{ display: "inline-flex"}}>
              {props.sound.charAt(0).toUpperCase() + props.sound.slice(1).replace(/([A-Z])/g, m => " " + m)}
              {/* {props.sound.charAt(0).toUpperCase() + props.sound.slice(1).replace(/([A-Z])/g, ' $1').toLowerCase()} */}

            </div>
            <MdFavoriteBorder style={{position: "relative", top: "2px", left: "10px", color: "#ff000080", cursor: "pointer" }}/>

            <div className="descriptionContent" style={{fontSize: "14px"}}>{props.description}</div>
            <div className="relatedTitleContent">Related Sounds</div>
            <div className="relatedContent" style={{fontSize: "14px"}}>{props.dconnection}</div>
            <button className="xContent closeDescription" onClick={() => changeIsExpanded()}>x</button>
            <button className="activateContent">{props.isactive ? "Desactivate Sound" : "Activate Sound"}</button>
          </motion.div>
        </div>

        <div className={`moreButton ${hoverTime >= 1000 ? ("displayMoreButton") : ("") }`}>
          <HiOutlineInformationCircle id="info" style={{background: "#211c31bf", borderRadius: "50%"}}/>
        </div>
    </motion.div>
  )
}















// import React, { useState, useEffect, useRef } from "react";
// import { motion, useAnimation, AnimatePresence } from "framer-motion";
// import { HiOutlineInformationCircle } from "react-icons/hi";
// import { MdFavoriteBorder } from "react-icons/md";
// import ExpendableCard from "../components/ExpendableCard";
// import "./Sound.css";

// // Sounds.js receives the following props - το soundState είναι το τοπικό DB του Sound.js: 
// // id                       => Χρησιμοποιείται στο setSoundState για να αποθηκεύσει το id του
// // sound                    => Χρησιμοποιείται σε διάφορα σημεία εντός του component για να αποθηκεύσει ή έμφασνίσει την ονομασία του ήχου
// // state(-)                 => Θεωρώ ότι δεν κάνει τίποτα και είναι προς διαγραφή
// // category                 => Χρησιμοποιείται μόνο μια φόρά για να ελεγχθεί αν ο ήχος που πληρκτρολόγησε ο χρήστης έχει κάποια σχέση με κατηγορία ενός άλλου ήχου (οταν γίνεται 1η φορά πληκτρολ.)
// // dconnection              => Χρησιμοποιείται μόνο έχει ήδη πληκτρολ. κάτι και ψάχνουμε να βρούμε αν είναι direct connected
// // indconnection            => Χρησιμοποιείται μόνο έχει ήδη πληκτρολ. κάτι και ψάχνουμε να βρούμε αν είναι indirect connected
// // description              => Χρησιμοποιείται μόνο στο modal content 
// // volume                   => Χρησιμοποιείται στο setSoundState για να αποθηκεύσει το volume του
// // isactive                 => Χρησιμοποιείται πρώτη φορά όταν ο ήχος είναι active για να ενεργοποιήσει το setSoundState - στο playsound και με classes
// // addSoundToDB*            => Χρησιμοποιείται όταν ο ήχος έχει μόλις δημιουργηθεί μέσω του του soundState 
// // removeSoundFromDΒ*       => Χρησιμοποιείται στο playSound και αν ειναι active τότε delete από την βάση
// // modifyisactive(-)        => Δεν χρησιμοποιείται πουθενά
// // activeName               => Χρησιμοποιείται για να εμφανίσει περιεχόμενο μέσω searchQterm
// // searchQ                  =>  
// // searchQterm              => 
// // lengthOf                 => Χρησιμοποιείται για να βάλει class στα relative μέσα στον κώδικα
// // firstMount               => Χρησιμοποιείται για να μην εμανίσει τίποτα στο 2ο load και για animation
// // tempCountDirectSounds    => 
// // tempCountRelativeSounds  => 
// // tempCountHiddenSounds    => 
// // countRelative            => 
// // countDirect              => 
// // countHidden              => 
// // triggerExpandShadow      => 

// export default function Sound(props) {
//   const [soundState, setSoundState] = useState({
//     id: 0,
//     playing: false,
//     volume: 1,
//     justcreated: true,
//   });
//   const [countSearchItems, setCountSearchItems] = useState(0);
//   const [animation, setAnimation] = useState(false);
//   const [hoverTime, setHoverTime] = useState(0);

//   const [isExpanded, setIsExpanded] = useState(false);


//   const isHovering = useRef(false);
//   const myRef = useRef(null);

//   useEffect(() => {
//     if (props.isactive) {
//       setSoundState({
//         ...soundState,
//         playing: true,
//         id: props.id,
//         volume: props.volume,
//       });
//     }
//   }, []);

//   useEffect(() => {
//     if (!soundState.justcreated) {
//       props.addSoundToDB(
//         soundState.id,
//         props.sound,
//         soundState.volume,
//         soundState.playing,
//         `../sounds/${props.sound}.mp3`,
//         true,
//         false
//       );
//     }
//   }, [soundState]);

//   function playSound() {
//     if (!props.isactive) {
//       setSoundState({
//         ...soundState,
//         justcreated: false,
//         playing: true,
//         id: props.id,
//         volume: props.volume,
//         // isactive: true,
//       });
//     } else if (props.isactive) {
//       setSoundState({
//         ...soundState,
//         justcreated: true,
//         playing: false,
//         id: 0,
//         volume: props.volume,
//         // isactive: false,
//       });
//       props.removeSoundFromDB(soundState.id);
//     }
//   }

//   useEffect(() => {
//     const classes = myRef.current.classList;
//     const stringified = JSON.stringify(classes);

//     const hasRelativeClass = stringified.includes("relative");
//     const hasHiddenClass = stringified.includes("hide");
//     const hasImportantClass = stringified.includes("importantDisplay");
//     const isRelatedTag = stringified.includes("dispnone");

//     if (hasRelativeClass) {
//       props.countRelative();
//     } else if (!hasRelativeClass && !hasHiddenClass) {
//       props.countDirect();
//     } else if (hasHiddenClass && !hasImportantClass && !isRelatedTag) {
//       props.countHidden();
//     }

//     if (props.searchQterm.length === 0) {
//       props.countRelative(0);
//       props.countDirect(0);
//       props.countHidden(0);
//     }
//   }, [props.searchQterm]);

//   useEffect(() => {
//     if (!props.firstMount) {
//       setAnimation(true);
//       const timeoutId = setTimeout(() => {
//         setAnimation(false);
//       }, 310);
//       return () => clearTimeout(timeoutId);
//     }
//   }, [props.searchQterm]);

//   const displayId = () => {
//     if (!isHovering.current) {
//       console.log(props.sound + " is hover ");
//       isHovering.current = true;
//       handleMouseOver();
//     }
//   };

//   const handleMouseLeave = () => {
//     isHovering.current = false;
//   };

//   const handleMouseOver = () => {
//     const startTime = Date.now();
//     const intervalId = setInterval(() => {
//       setHoverTime(Date.now() - startTime);
//     }, 10);

//     myRef.current.addEventListener("mouseleave", () => {
//       clearInterval(intervalId);
//       setHoverTime(0);
//     });
//   };

//   const { innerWidth, innerHeight } = window;

//   useEffect(() => {
//     console.log(innerWidth);
//     console.log(innerHeight);
//   }, [innerWidth, innerHeight]);

//   const [posL, setPosL] = useState()
//   const [posR, setPosR] = useState()
//   const [posB, setPosB] = useState()
//   const [posDifX, setPosDifX] = useState()
//   const [posDifY, setPosDifY] = useState()

//   useEffect(() => {
//     console.log(posL)
//     console.log(posR)
//   }, [posL, posR]);

//   const onClick = (event) => {

//     const classNameSVG = event.target.baseVal;
//     const classNameDIV = event.target.className;
//     const idDIV = event.target.id;
//     const tagName = event.target.tagName;
  
//     console.log(event.target);
//     console.log(classNameSVG);
//     console.log(classNameDIV);
//     console.log(idDIV);
//     console.log(event.target.tagName);

//     const element = event.target.parentElement;
//     const position = element.getBoundingClientRect();

//     const width = window.getComputedStyle(event.target).getPropertyValue('width');
//     console.log(width)    

//     console.log(idDIV === "expandedCard")

//     if (classNameSVG === undefined && tagName !== "DIV" || tagName === "DIV" && !classNameDIV.includes("activateContent") && classNameDIV.includes("moreButton") && width !== "500px") {
//       console.log("prevent click")
//       console.log(classNameDIV)
//       // console.log(element);
//       console.log(position);
//       // console.log(position.right - position.left);
//       setPosL(position.left)
//       setPosR(position.right)
//       setPosR(position.bottom)
//       setPosDifX(innerWidth - position.right)
//       setPosDifY(innerHeight - position.bottom)

//       changeIsExpanded();
//     } 

//     if (tagName === "DIV" && idDIV === "expandedCard" && width !== "500px") {
//       console.log(idDIV)
//       playSound();
//       props.justClicked(!soundState.playing)
//     } else if (tagName === "BUTTON" && classNameDIV.includes("activateContent")) {
//       playSound();
//       props.justClicked(!soundState.playing)
//     }else if (tagName === "BUTTON" && classNameDIV.includes("moreButton")) {
//       console.log("tagName => " + tagName)
//       // console.log(idDIV)
//       // console.log(classNameDIV)

//       // console.log(element);
//       console.log(position);
//       // console.log(position.right - position.left);
//       setPosL(position.left)
//       setPosR(position.right)
//       setPosR(position.bottom)
//       setPosDifX(innerWidth - position.right)
//       setPosDifY(innerHeight - position.bottom)

//       changeIsExpanded();
//     }
//   };

//   const changeIsExpanded = () => {
//     console.log("Testtttttttttttttttttttttttttttttttttttttttttttttttttt")
//     setIsExpanded(!isExpanded)

//     if (!isExpanded) {
//       document.body.style.overflow = 'hidden';
//     } else {
//       document.body.style.overflow = '';
//     }
//     // document.body.classList.toggle("modal-open")
//     props.triggerExpandShadow(!isExpanded)
//     console.log("=> "+ !isExpanded)
//   }

//   const customExpansion = {
//     display: isExpanded ? "block" : "",
//     opacity: isExpanded ? "1" : "0",
//     position: "absolute",
//     width: isExpanded ? "500px" : "56px",
//     height: isExpanded ? "350px" : "56px",
//     background: isExpanded ? "rgba(255, 255, 255, 1)" : "",
//     boxShadow: isExpanded ? "0px 4px 4px rgba(0, 0, 0, 0.25)" : "",
//     borderRadius: "12px",
//     transform: isExpanded ? `translate(calc(-50vw + 28px + ${posDifX}px - 10px), calc(-50vh + 28px + ${posDifY}px - 0px))` : "",
//     transition: "all 300ms ease-in-out 0s",
//     padding: isExpanded ? "20px" : "0",
//     zIndex: isExpanded ? "22" : "0",
//     cursor: isExpanded ? "initial" : "pointer"
//   }

//   const customExpansionContent = {
//     display: isExpanded ? "block" : "none",
//   }

//   const [triggetAnimation, setTriggetAnimation] = useState(false);

//   useEffect(() => {
//     setTriggetAnimation(true)

//     // Use the useEffect hook to monitor changes in props.activeName
//     if (props.activeName) {
//       // When props.activeName becomes true, trigger the animation
//       const animationTimeout = setTimeout(() => {
//         setTriggetAnimation(false);
//       }, 1000); 
//       return () => clearTimeout(animationTimeout);
//     }
//   }, [props.activeName]);

//   return (
//     <motion.div
//       initial={{ opacity: 0, y: 0 }}
//       animate={{ opacity: 1, y: "0px" }}
//       transition={{ delay: 1.8, duration: 0.3 }}
//       onMouseOver={displayId}
//       onMouseLeave={handleMouseLeave}
//       // ${isExpanded ? 'enlarged' : ''}

//       ref={myRef}
//       id="ypnosSound"
//       className={`
//         sound-style
//         ${triggetAnimation ? 'animate' : ''}

//         ${isExpanded ? 'enlarged' : ''}

//         ${hoverTime >= 200 ? ("pressed") : ("") }

//         ${props.isactive ? ("active") : ("")} 
       
//         ${animation ? "fadeOut" : "fadeIn"}
        
//         ${props.firstMount ? ("firstLoad") : ("")}

//         ${(() => {
//           if (props.searchQterm.length === 0) {
//             if (props.activeName === 'All') {
//               return 'importantDisplay'
//             } 
//           } 
//           return ''
//         })()}

//         ${(() => {
//           //ΜΠΑΙΝΕΙ ΕΔΩ ΜΟΝΟ ΟΤΑΝ ΔΕΝ ΕΧΕΙ ΠΛΗΚΤΡΟΛΟΓΗΘΕΙ ΤΙΠΟΤΑ
//           if (props.searchQterm.length === 0) {
//             // ΑΝ Ο ΗΧΟΣ ΕΧΕΙ ΩΣ ΜΙΑ ΑΠΟ ΤΙΣ ΚΑΤΗΓΟΡΙΕΣ ΤΟΥ ΤΟ ΕΝΕΡΓΟ ΕΠΙΛΕΓΜΕΝΟ ΦΙΛΤΡΟ, ΤΟΤΕ ΔΕΝ ΓΙΝΕΤΑΙ ΤΠΤ, ΑΛΛΙΩΣ ΤΟ ΚΡΥΒΟΥΜΕ.
//             if (props.category.includes(props.activeName)) {
//               return ''              
//             } else {
//               return 'hide'
//             }
//           } else {
//             if (props.dconnection.includes(props.searchQterm.toLowerCase())) {
//               return ''
//             } else if (props.indconnection.includes(props.searchQterm.toLowerCase())) {
//               return 'relative'
//             } else {
//               return 'hide'
//             }
//           }
//         })()}

//         ${props.sound === "Reccomended" ? ("dispnone") : ("")} 
        
//         ${props.sound === "Reccomended" && props.tempCountRelativeSounds !== 0 ? ("disp") : ("")} 
        
//         ${props.sound === "Reccomended" && props.tempCountSearchItems === props.lengthOf ? ("hiderel") : ("")} 
//       `}
//       onClick={onClick}>
//         <span className={`activeSoundContent ${triggetAnimation ? 'animate' : ''}`} style={{fontSize: "10px", display: "block", textAlign: "center"}}>
//           {props.sound.charAt(0).toUpperCase() + props.sound.slice(1).replace(/([A-Z])/g, m => " " + m)}
//           {/*IoMdBonfire*/}
//           {/*WiRainMix*/}
//           {/*GiForestCamp*/}
//         </span>
//         <div id="expandedCard" style={customExpansion}>
//           <motion.div 
//             className="content" 
//             style={customExpansionContent}
//             animate={{ opacity: isExpanded ? 1 : 0 }}
//             transition={{ type: "tween", duration: 0.05, delay: isExpanded ? 0.28 : 0 }}>
//             <div className="titleContent" style={{ display: "inline-flex"}}>
//               {props.sound.charAt(0).toUpperCase() + props.sound.slice(1).replace(/([A-Z])/g, m => " " + m)}
//               {/* {props.sound.charAt(0).toUpperCase() + props.sound.slice(1).replace(/([A-Z])/g, ' $1').toLowerCase()} */}

//             </div>
//             <MdFavoriteBorder style={{position: "relative", top: "2px", left: "10px", color: "#ff000080", cursor: "pointer" }}/>

//             <div className="descriptionContent" style={{fontSize: "14px"}}>{props.description}</div>
//             <div className="relatedTitleContent">Related Sounds</div>
//             <div className="relatedContent" style={{fontSize: "14px"}}>{props.dconnection}</div>
//             <button className="xContent closeDescription" onClick={() => changeIsExpanded()}>x</button>
//             <button className="activateContent">{props.isactive ? "Desactivate Sound" : "Activate Sound"}</button>
//           </motion.div>
//         </div>

//         <div className={`moreButton ${hoverTime >= 1000 ? ("displayMoreButton") : ("") }`}>
//           <HiOutlineInformationCircle id="info" style={{background: "#211c31bf", borderRadius: "50%"}}/>
//         </div>
//     </motion.div>
//   )
// }