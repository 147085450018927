import React, {useState, useEffect} from "react"

export default function Cplayer(props) {
    const [audio] = useState(new Audio(props.src))

    useEffect(() => {
      audio.src = props.src;
    }, [props.src]);

    useEffect(() => {
        const handleEnded = () => {
          audio.play();
        };
      
        audio.volume = props.volume;

        audio.addEventListener("ended", handleEnded);
      
        if (props.playing) {
          if (audio.paused) {
            audio.play().catch((error) => console.log("Audio play error:", error));
          }
        } else {
          audio.pause();
        }       
      
        return () => {
          audio.removeEventListener("ended", handleEnded);
          audio.pause();
          audio.currentTime = 0;
        };
      }, [props.playing, props.volume, props.src]);

    

    // Set volume according to props
    audio.volume = props.volume

    // Return JSX for audio player component
    return (
        <div className="audio-player">
            
        </div>
    )
}