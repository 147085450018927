import React, {useState, useEffect} from "react"

import './Filter.css'

export default function Filter(props) {
    useEffect(() => {
        
    })

    const trigger = () => {
        props.changeActiveId(props.id)
        props.clearTextFun()
    }

    return (
        <div 
            className={`label ${props.active == props.id ? (!props.searchHasText ? ("active") : ("")) : ("")}`} 
            onClick={trigger} style={{ width: props.label === 'White Noise' ? '85px' : 'auto' }}>
            {props.label}
        </div>
    )
}

