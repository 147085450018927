export default [
    {
        id: 1,
        name: "rain",
        filename: "",
        category: "Nature, Sleep",
        dconnection: "rain, cold, winter, water",
        indconnection: "fire, fireplace",
        description: "As you close your eyes, you find yourself surrounded by a world of tranquility. You can hear the gentle patter of raindrops, each one a soothing melody that lulls you into a state of relaxation. The sound is soft and gentle, a soothing balm for your tired mind. You imagine yourself walking along a forest path, the rain falling gently on the leaves and flowers around you. The scent of the rain fills your nostrils, and you feel refreshed and invigorated. The rain is a symbol of renewal, a sign that the earth is being nourished and renewed. You feel at peace, knowing that everything is as it should be, and that you are exactly where you need to be.",
        icon: "",
        state: "true",
        volume: 0.4
    },
    {
        id: 2,
        name: "lake",
        filename: "",
        category: "Nature, Sleep",
        dconnection: "lake, water, nature",
        indconnection: "rain",
        description: "Imagine yourself standing on the banks of a serene lake, surrounded by towering trees and chirping birds. You feel a gentle breeze brush against your face, as the sun sets behind the distant mountains, painting the sky with warm hues of orange and pink. The water in the lake is crystal clear, reflecting the stunning beauty of the landscape around you. You close your eyes, take a deep breath, and feel all your worries and stresses slowly melt away. The sounds of water lapping against the shore, birds singing, and leaves rustling in the wind create a soothing and tranquil atmosphere that rejuvenates your mind, body, and soul. This is a perfect soundscape to help you relax, meditate, or simply escape from the chaos of daily life.",
        icon: "",
        state: "false",
        volume: 1
    },
    {
        id: 3,
        name: "forestRain",
        filename: "",
        category: "Nature, Sleep",
        dconnection: "rain, cold, winter, water, forestrain",
        indconnection: "",
        description: "Take a journey deep into the heart of a lush rainforest, where the soothing sounds of a gentle rain will transport you to a world of tranquility and peace. The rainforest is alive with the sounds of birds chirping, leaves rustling in the wind, and water trickling through streams. As you immerse yourself in the rich, verdant environment, allow the calming sounds to wash over you and quiet your mind. Close your eyes, breathe deeply, and let the beauty of nature carry you away to a place of relaxation and serenity.",
        icon: "",
        state: "false",
        volume: 0.3
    },
    {
        id: 4,
        name: "fireplace",
        filename: "",
        category: "New, Nature, Sleep, Rooms, Trending",
        dconnection: "fire, fireplace, warm, cozy, winter",
        indconnection: "rain",
        description: "Settle in by a cozy fire, as you listen to the comforting sound of logs crackling and popping in the flames. This tranquil soundscape will transport you to a warm and inviting space, where the glow of the fire casts a soft, amber light over everything. As you listen to the gentle sound of the fire, feel yourself relax and unwind, letting go of the stresses of the day and finding comfort in the warmth and safety of the fire.",
        icon: "",
        state: "false",
        volume: 1
    },
    {
        id: 5,
        name: "keyboardTyping",
        filename: "",
        category: "New, ASMR, Rooms",
        dconnection: "relaxation, asmr, keyboardtyping",
        indconnection: "rain, fireplace, drawing, plasticbag",
        description: "The sound of keyboard typing is a familiar and comforting one, evoking images of productivity, creativity, and focus. Whether you're a writer, a programmer, or just someone who loves the satisfying clickety-clack of keys being pressed, this sound is sure to help you get in the zone and stay there. With each keystroke, you'll feel yourself becoming more immersed in your work, more attuned to the task at hand. And when you take a break, you'll appreciate the sense of accomplishment that comes from knowing you've made progress towards your goals.",
        icon: "",
        state: "false",
        volume: 0.7
    },
    {
        id: 6,
        name: "drawing",
        filename: "",
        category: "New, ASMR",
        dconnection: "asmr, human, ambience",
        indconnection: "rain, drawing, keyboardtyping",
        description: "Immerse yourself in the world of creativity with the sound of an artist's pencil sketching on paper. The gentle strokes of the pencil create a calming and tranquil environment, allowing your mind to relax and explore the endless possibilities of art. With each stroke, you can feel your creativity flowing, allowing you to tap into your inner artist and express yourself freely.",
        icon: "",
        state: "false",
        volume: 1
    },
    {
        id: 7,
        name: "plasticBag",
        filename: "",
        category: "ASMR",
        dconnection: "asmr, human, ambience",
        indconnection: "drawing, keyboardtyping",
        description: "The sound of a plastic bag being crinkled and rustled can be both relaxing and comforting. This sound is often associated with the familiar act of grocery shopping or packing, and can transport you to a cozy place of warmth and safety. Close your eyes and imagine yourself lying in a hammock on a warm summer day, listening to the soft rustling of leaves as a gentle breeze blows through the trees. As the plastic bag rustles softly, you can feel all your worries and stress slip away, and you are left with a feeling of calm and contentment.",
        icon: "",
        state: "false",
        volume: 1
    },
    {
        id: 8,
        name: "vehicleInterior",
        filename: "",
        category: "New, ASMR",
        dconnection: "vehicle, car, asmr",
        indconnection: "plasticbag, drawing, kayboardtyping, human",
        description: "Immerse yourself in the sound of a peaceful drive with Vehicle Interior. This sound will transport you into the driver's seat of your favorite car, truck, or van, allowing you to experience the gentle hum of the engine, the soft creaking of the leather seats, and the gentle sound of the wind rushing by. Whether you're looking to relax after a long day, or simply want to bring a little bit of the open road into your workspace, Vehicle Interior is the perfect soundtrack for any occasion.",
        icon: "",
        state: "false",
        volume: 0.5
    },
    {
        id: 9,
        name: "womanWhispering",
        filename: "",
        category: "ASMR",
        dconnection: "asmr, whisper, human",
        indconnection: "plasticbag, drawing, kayboardtyping",
        description: "Close your eyes and feel the gentle breeze of whispered words caressing your ears. Let the soft whispers carry you away to a peaceful realm of relaxation, where the only thing that matters is the soothing sound of a woman's voice. As you listen, imagine yourself lying on a warm sandy beach, with the gentle waves lapping at your feet. Or perhaps you are nestled in a cozy cabin, surrounded by the sounds of nature as you drift off into a deep and restful sleep. Whatever your imagination conjures up, let the secret whispers transport you to a place of inner peace and tranquility.",
        icon: "",
        state: "false",
        volume: 0.2
    },
    {
        id: 10,
        name: "thunder",
        filename: "Nature, Sleep",
        category: "",
        dconnection: "rain, thunder",
        indconnection: "rain, fireplace",
        description: "Embrace the power and beauty of a thunderstorm, as you listen to the soothing sound of raindrops tapping against the windowpane and the distant rumble of thunder. This immersive soundscape will transport you to the heart of the storm, where you'll feel the raw energy and intensity of the elements. As you listen to the rain and thunder, allow yourself to feel grounded and centered, safe and secure in the midst of the storm.",
        icon: "",
        state: "false",
        volume: 1
    },
    {
        id: 11,
        name: "rain",
        filename: "",
        category: "Nature, Sleep",
        dconnection: "rain",
        indconnection: "",
        description: "",
        icon: "",
        state: "false",
        volume: 1
    },
    {
        id: 12,
        name: "lake",
        filename: "Nature, Sleep",
        category: "",
        dconnection: "",
        indconnection: "rain",
        description: "",
        icon: "",
        state: "false",
        volume: 1
    },
    {
        id: 13,
        name: "rain",
        filename: "",
        category: "Nature, Sleep",
        dconnection: "rain",
        indconnection: "",
        description: "",
        icon: "",
        state: "false",
        volume: 1
    },
    {
        id: 14,
        name: "lake",
        filename: "Nature, Sleep",
        category: "",
        dconnection: "",
        indconnection: "rain",
        description: "",
        icon: "",
        state: "false",
        volume: 1
    },
    {
        id: 15,
        name: "rain",
        filename: "",
        category: "Nature, Sleep",
        dconnection: "rain",
        indconnection: "",
        description: "",
        icon: "",
        state: "false",
        volume: 1
    },
    {
        id: 16,
        name: "lake",
        filename: "Nature, Sleep",
        category: "",
        dconnection: "",
        indconnection: "rain",
        description: "",
        icon: "",
        state: "false",
        volume: 1
    },
    {
        id: 17,
        name: "rain",
        filename: "",
        category: "Nature, Sleep",
        dconnection: "rain",
        indconnection: "",
        description: "",
        icon: "",
        state: "false",
        volume: 1
    },
    {
        id: 18,
        name: "lake",
        filename: "Nature, Sleep",
        category: "",
        dconnection: "",
        indconnection: "rain",
        description: "",
        icon: "",
        state: "false",
        volume: 1
    },
    {
        id: 19,
        name: "rain",
        filename: "",
        category: "Nature, Sleep",
        dconnection: "rain",
        indconnection: "",
        description: "",
        icon: "",
        state: "false",
        volume: 1
    },
    {
        id: 20,
        name: "lake",
        filename: "Nature, Sleep",
        category: "",
        dconnection: "",
        indconnection: "rain",
        description: "",
        icon: "",
        state: "false",
        volume: 1
    },
    {
        id: 21,
        name: "rain",
        filename: "",
        category: "Nature, Sleep",
        dconnection: "rain",
        indconnection: "",
        description: "",
        icon: "",
        state: "false",
        volume: 1
    },
    {
        id: 22,
        name: "lake",
        filename: "Nature, Sleep",
        category: "",
        dconnection: "",
        indconnection: "rain",
        description: "",
        icon: "",
        state: "false",
        volume: 1
    },
    {
        id: 23,
        name: "rain",
        filename: "",
        category: "Nature, Sleep",
        dconnection: "rain",
        indconnection: "",
        description: "",
        icon: "",
        state: "false",
        volume: 1
    },
    {
        id: 24,
        name: "lake",
        filename: "Nature, Sleep",
        category: "",
        dconnection: "",
        indconnection: "rain",
        description: "",
        icon: "",
        state: "false",
        volume: 1
    },
    {
        id: 25,
        name: "rain",
        filename: "",
        category: "Nature, Sleep",
        dconnection: "rain",
        indconnection: "",
        description: "",
        icon: "",
        state: "false",
        volume: 1
    },
    {
        id: 26,
        name: "lake",
        filename: "Nature, Sleep",
        category: "",
        dconnection: "",
        indconnection: "rain",
        description: "",
        icon: "",
        state: "false",
        volume: 1
    },
    {
        id: 27,
        name: "rain",
        filename: "",
        category: "Nature, Sleep",
        dconnection: "rain",
        indconnection: "",
        description: "",
        icon: "",
        state: "false",
        volume: 1
    },
    {
        id: 28,
        name: "lake",
        filename: "Nature, Sleep",
        category: "",
        dconnection: "",
        indconnection: "rain",
        description: "",
        icon: "",
        state: "false",
        volume: 1
    },
    {
        id: 29,
        name: "rain",
        filename: "",
        category: "Nature, Sleep",
        dconnection: "rain",
        indconnection: "",
        description: "",
        icon: "",
        state: "false",
        volume: 1
    },
    {
        id: 30,
        name: "lake",
        filename: "Nature, Sleep",
        category: "",
        dconnection: "",
        indconnection: "rain",
        description: "",
        icon: "",
        state: "false",
        volume: 1
    },
    {
        id: 31,
        name: "rain",
        filename: "",
        category: "Nature, Sleep",
        dconnection: "rain",
        indconnection: "",
        description: "",
        icon: "",
        state: "false",
        volume: 1
    },
    {
        id: 32,
        name: "lake",
        filename: "Nature, Sleep",
        category: "",
        dconnection: "",
        indconnection: "rain",
        description: "",
        icon: "",
        state: "false",
        volume: 1
    },
    {
        id: 33,
        name: "rain",
        filename: "",
        category: "Nature, Sleep",
        dconnection: "rain",
        indconnection: "",
        description: "",
        icon: "",
        state: "false",
        volume: 1
    },
    {
        id: 34,
        name: "lake",
        filename: "Nature, Sleep",
        category: "",
        dconnection: "",
        indconnection: "rain",
        description: "",
        icon: "",
        state: "false",
        volume: 1
    },
    {
        id: 35,
        name: "rain",
        filename: "",
        category: "Nature, Sleep",
        dconnection: "rain",
        indconnection: "",
        description: "",
        icon: "",
        state: "false",
        volume: 1
    },
    {
        id: 36,
        name: "lake",
        filename: "Nature, Sleep",
        category: "",
        dconnection: "",
        indconnection: "rain",
        description: "",
        icon: "",
        state: "false",
        volume: 1
    },
    {
        id: 37,
        name: "Reccomended",
        filename: "null",
        category: "null",
        dconnection: "null",
        indconnection: "null",
        description: "",
        icon: "",
        state: "false",
        volume: 1
    }
]