import React, {useState, useEffect} from "react"
import ReactSearchBox from 'react-search-box'
import soundsDB from "../data/soundsDB.js"
import Filter from './Filter.js'
import SearchBox from './SearchBox.js'
import './Filters.css'

import { motion, useAnimation, AnimatePresence } from "framer-motion"

import { BsChatSquareQuote } from 'react-icons/bs';

import ExpendableCard from '../components/ExpendableCard'

export default function Filters(props) {
  const [userInput, setUserInput] = useState("");
  const [searchHasText, setSearchHasText] = useState(false)
  const [clearText, setClearText] = useState(false)

  const clearTextFun = () => {
    setClearText(true)
  }  

  const resetClearTextFun = () => {
    setClearText(false)
  }

  const searchData = soundsDB.map((sound) => {
    return {
      key: sound.id,
      value: sound.name
    }
  })

  const handleInputChange = (value) => {
    props.getSearch(value)
    setUserInput(value)
  }

  // Set the state seachHasText to "true" or "false" base on the user input.
  const hasText = (value) => {
    setSearchHasText(value)
  }

  const displayFilters = props.data.map((filter) => {    
    return (
      <Filter
        key={filter.id}
        id={filter.id}
        label={filter.name}
        active={props.activeId}
        changeActiveId={props.changeActiveId}
        searchHasText={searchHasText}
        clearTextFun={clearTextFun}>
      </Filter>
    ) 
  })

  const openCard = () => {

  }

  const [scrollPos, setScrollPos] = useState(0);
  const [divClass, setDivClass] = useState("");

  const threshold = 50;
  function handleScroll() {
    const currentScrollPos = window.pageYOffset || document.documentElement.scrollTop;
    setScrollPos(currentScrollPos);
  }
 
  useEffect(() => {
    if (scrollPos > threshold) {
      setDivClass("scrolled");
    } else {
      setDivClass("");
    }
  }, [scrollPos]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className={`filter`}>
      <div className={`filter-container ${divClass}`}>
        {displayFilters}

        <SearchBox
          placeholder="Type a sound..."
          onChange={handleInputChange}
          hasText={hasText}
          clearText={clearText}
          resetClearTextFun={resetClearTextFun}
          changeActiveId={props.changeActiveId}
          activeId={props.activeId}
        />

        <div className="expendable-card-container">
          <ExpendableCard id={1} name={"Name"} description={"Description"} expandShadow={props.expandShadow} triggerExpandShadow={props.triggerExpandShadow}/>
        </div>
        {/*<p>You typed: {text}</p>*/}
      </div>
      
    </div>
  )
}