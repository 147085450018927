import React, { useState, useRef, useEffect } from "react"
import { useLocation } from "react-router-dom";

import './SearchBox.css'

// Props used from parent -----------------------------------
// onChange				=>
// hasText				=>
// clearText			=>
// resetClearTextFun	=>
// changeActiveId		=>
// activeId				=>

export default function SearchBox(props) {
	const [value, setValue] = useState("")
	const [searchHasText, setSearchHasText] = useState(false)
	const [actId, setActId] = useState("")

	// It's used in order to make the search focus on first load (inside handleClear), but currently inactive.
	const inputRef = useRef(null)

	const handleClear = () => {
		setValue("")
		props.onChange("")
		// inputRef.current.focus()
		setSearchHasText(false)
	}

	const handleChange = (event) => {
		setValue(event.target.value)
		props.onChange(event.target.value)
	}

	useEffect(() => {
    	setSearchHasText(!!value)
    	console.log(value)
  	}, [value])

  	useEffect(() => {
    	setSearchHasText(!!value)
    	props.hasText(searchHasText)
  	}, [searchHasText])

  	useEffect(() => {
    	if (props.clearText) {
    		handleClear()
    		props.resetClearTextFun()
    	}
    	console.log("A filter was clicked while the input search was not empty: " + props.clearText)
  	}, [props.clearText])
  	
  	let location = useLocation();

  	useEffect(() => {
	  if (location.pathname === '/') {
	    handleClear();
	  }
	}, [location.pathname]);

  	const [scrollPos, setScrollPos] = useState(0);
	const [divClass, setDivClass] = useState("");

	const threshold = 50;
	function handleScroll() {
		const currentScrollPos = window.pageYOffset || document.documentElement.scrollTop;
		setScrollPos(currentScrollPos);
	}

	useEffect(() => {
	if (scrollPos > threshold) {
	  setDivClass("scrolled");
	} else {
	  setDivClass("");
	}
	}, [scrollPos]);

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);

		return () => {
		window.removeEventListener("scroll", handleScroll);
		};
	}, []);


	return (
		<div className="input-container">
			<input
				type="text"
				placeholder={props.placeholder}
				className={`default ${divClass}`}
				onChange={handleChange}
				value={value}
				ref={inputRef}
			/>
			{value && (
				<span className="clear-icon" onMouseDown={handleClear} aria-label="Clear">
					X
				</span>
			)}
	    </div>
	)
}