export default [
    {
        id: 1,
        name: "All",
        filename: "",
        category: "",
        description: "",
        icon: "",
        state: "true",
        volume: 0
    },
    {
        id: 2,
        name: "New",
        filename: "",
        category: "",
        description: "",
        icon: "",
        state: "false",
        volume: 1
    },
    {
        id: 3,
        name: "Trending",
        filename: "",
        category: "",
        description: "",
        icon: "",
        state: "false",
        volume: 1
    },
    {
        id: 4,
        name: "Nature",
        filename: "",
        category: "",
        description: "",
        icon: "",
        state: "false",
        volume: 1
    },
    {
        id: 5,
        name: "Animals",
        filename: "",
        category: "",
        description: "",
        icon: "",
        state: "false",
        volume: 1
    },
    {
        id: 6,
        name: "Sleep",
        filename: "",
        category: "",
        description: "",
        icon: "",
        state: "false",
        volume: 1
    },
    {
        id: 7,
        name: "City",
        filename: "",
        category: "",
        description: "",
        icon: "",
        state: "false",
        volume: 1
    },
    {
        id: 8,
        name: "ASMR",
        filename: "",
        category: "",
        description: "",
        icon: "",
        state: "false",
        volume: 1
    },
    {
        id: 9,
        name: "Rooms",
        filename: "",
        category: "",
        description: "",
        icon: "",
        state: "false",
        volume: 1
    },
    {
        id: 10,
        name: "White Noise",
        filename: "",
        category: "",
        description: "",
        icon: "",
        state: "false",
        volume: 1
    },  
]