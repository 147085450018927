import React from 'react'
import ReactDOM from 'react-dom'

import { Link } from "react-router-dom";


export default function Error() {
    return (
        <div>
            <span>Error 404</span>
            <br />
            <Link to="/">Back to home</Link>
        </div>
    )
}