import React from 'react'
import ReactDOM from 'react-dom'

import './Mainview.css'

// See the data-binding project > components > MainContent.js for the slot attribute.

export default function Mainview(props) {
    return (
        <div className='mainview'>
            <div className='container'>
                {props.children}
            </div>
        </div>
    )
}