import { motion, AnimatePresence } from "framer-motion";
import { useState, useEffect } from "react";

import { BsChatSquareQuote } from 'react-icons/bs';

import './ExpendableCard.css'

export default function ExpendableCard(props) {
  const [expanded, setExpanded] = useState(false);
  const [iconClass, setIconClass] = useState('');

  const handleClick = (event) => {
    const className = event.target.className.baseVal;
    if (!className.includes("expanded") || className === undefined ) {
      if (!expanded) {
        setExpanded(!expanded);
        document.body.classList.remove("modal-open");
      } else {
        setExpanded(!expanded);
        document.body.classList.add("modal-open");
      }
      document.body.classList.toggle("modal-open");

      
    }
    if (expanded) {
        setIconClass('tempStyle');
        setTimeout(() => {
          setIconClass('');
        }, 220);
      } 
  }

  useEffect(() => {
    if (!expanded) {
      document.body.classList.toggle("modal-open");
    // props.triggerExpandShadow(expanded)

    }
  },[expanded])

  return (
    <>
      <AnimatePresence>
        {props.expandShadow && (
          <motion.div
            key="modal-background"
            className="modal-background"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0.4 } }}
            exit={{ opacity: 0, transition: { duration: 0.6 } }}
          />
        )}
      </AnimatePresence>
      <motion.div
        layoutId={props.id}
        onClick={handleClick}
        className={`card ${expanded ? 'expanded' : ''}`}
        transition={{ duration: 0.25 }}>
        <div className="card-header">
          {!expanded &&<BsChatSquareQuote className={`request ${!expanded ? 'block' : ''}  ${iconClass}`} size={16}/>}
        </div>
        <div className="card-body">
          {expanded && <p>{props.name}</p>}
          {expanded && <p>{props.description}</p>}
        </div>
        {expanded && <button className="close" onClick={() => setExpanded(!expanded)}>x</button>}
      </motion.div>
    </>
  );
}
