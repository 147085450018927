import React, {useState, useEffect, useRef} from "react"

import { motion, useAnimation, AnimatePresence } from "framer-motion"

export default function About() {
    const about = {
        background: "white",
        position: "absolute",
        bottom: 0,
        left: 0,
        width: "100%",
        height: "100%",
    }

    return (
        <motion.div
            style={about}
            initial={{ opacity: 0, y: 300 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2, duration: 0.3 }}>
            <div style={{height: "2000px"}}>About page</div>
        </motion.div>
    )
}
